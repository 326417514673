
/* cancel event */
.cancelEvent{
    display: grid;
    grid-template-columns: 1fr 400px;
    width: 100%;
    padding: 150px 40px 50px 40px;
    /* min-height: calc(100vh - 450px); */
    gap: 20px;
  }
  .canceleventcontent{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .cancelback{
    font-size: var(--font-size-13);
    color: rgba(125, 125, 141, 1);
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .cancelback svg{
    transform: rotate(180deg);
  }
  .canceleventmessage{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;
  }
  .canceleventmessage p{
    font-size: .9rem;
  }
  .canceleventmessage textarea{
    border: none;
    outline: none;
    background-color: #F5F5F6;
    border-radius: 8px;
    height: auto;
    min-height: 220px;
    max-width: 700px;
    padding: 15px;
    font-family: "Inter",sans-serif;
    font-size: .9rem;
    resize: none;
  }
  .canceleventmessage .AddEventFooter{
    max-width: 700px;
    height: auto !important;
    border: none;
  }
  .canceleventmessage .AddEventFooter .AddEventNextBtn{
    background-color: #FF1717;
  }
  .canceleventcontainer .eventTile{
    margin-left: auto;
  }
  @media screen and (min-width: 1400px) {
    .cancelEvent{
  
      padding: 150px 14% 50px 14%;
  
    }
  }