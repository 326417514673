.feed {
    position: relative;
    margin: 0 40px;
}

.feedWrapper {
    position: relative;
    margin-top: 152px;
    /* overflow: hidden; */
    /* overflow: -moz-hidden-unscrollable; */
    display: grid;
    grid-template-columns: minmax(130px, 310px) minmax(548px, 1fr);
    gap: 40px;
}

.feedLeftSidebar {
    position: relative;
    display: block;
}

.feedLeftSidebarWrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 152px;
    /* position: ; */
    display: flex;
    flex-direction: column;
    gap: 24px;


}

.feedLeftSidebarHeading {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.feedLeftSidebarHeading h2 {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.feedLeftSidebarHeading svg {
    margin-left: 5px;
}

.feedLinks {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: 1fr;
      grid-template-rows: repeat(40px); */
}

.feedLink {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: var(--font-size-13);
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    /* transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out; */
}

.feedLinkactive {
    color: var(--cl_orange);
    background-color: var(--cl_light_orange);
}

.feedRightSidebar {
    display: none;

}

@media (min-width: 1500px) {
    .feedWrapper {
        grid-template-columns: minmax(130px, 310px) minmax(548px, 1fr) 312px;
    }

    .feedRightSidebar {
        position: relative;
        display: block;

    }

    .feedRightSidebarWrapper {
        position: fixed;

        /* position: sticky;
      position: -webkit-sticky; */

        top: 152px;
        display: grid;
        grid-template-rows: 390px 1fr;
        /* display: flex; */
        flex-direction: column;
        gap: 40px;
        /* padding-bottom: 30px; */
        height: calc(100vh - 152px);
        /* overflow-y: auto; */
        /* overflow-x: hidden; */
    }
}

.feedRightSidebarWrapper .miniFeatured {
    display: grid;
    grid-template-rows: auto 1fr !important;
}

.feedRightSidebarWrapper .miniFeaturedListScroll {
    height: 100%;
}







.FeedLayout {
    min-height: 100vh;
    /* display: flex;
      flex-direction: row;
      gap: 5px; */
    display: grid;
    grid-template-columns: minmax(130px, 310px) minmax(548px, 1fr);
    gap: 15px;
    justify-content: space-between;
    margin: 130px 40px 0 40px;
    /* width: calc(100% - 80px); */
    /* position: relative !important; */

    /* position: fixed;
      max-height: calc(100vh-130px);
      overflow: hidden; */

}

/* @media (min-width: 1200px) {
      .FeedLayout {
          width: 72%;
        }
      } */
@media (min-width: 1400px) {
    .FeedLayout {
        margin: 130px 12% 0 12%;
    }
}

@media (min-width: 1600px) {
    .FeedLayout {
        grid-template-columns: minmax(130px, 310px) minmax(548px, 1fr) 312px;
    }
}

/* ========================================= sidebar */
.feedsidebarwrapper {
    position: relative;
    overflow: hidden;
}

.FeedSideBar {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    /* position: fixed; */
    /* top: 140px; */
    height: calc(100% - 140px);
    width: inherit;
    background-color: white;
}

.FeedSideBarTitleDiv {
    display: flex;
    flex-direction: column;
}

.FeedSideBarTitle {
    font-size: 1.4rem;
    font-weight: 600;
    padding: 5px 0;
}

.FeedSideBarSub {
    font-size: 0.8125rem;
    font-weight: 400;
    color: rgba(125, 125, 141, 1);
    padding: 10px 0;
}

.FeedSideBarList {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 100%;
}

.FeedSideBarItem,
.FeedSideBarItemActive {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    width: 100%;
    margin: 2px 0;
    border-radius: 8px;
    font-size: .8rem;
}

.FeedSideBarItem {
    background-color: white;
    color: black;
}

.FeedSideBarItemActive {
    background-color: var(--cl_light_orange);
    color: var(--cl_orange);
}

@media (min-width: 1200px) {
    .FeedSideBar {
        width: 100%;
    }

    .FeedSideBarTitle {
        font-size: 1.5rem;
    }

    .FeedSideBarSub {
        font-size: 0.8rem;
    }

    .FeedSideBarItem,
    .FeedSideBarItemActive {
        font-size: .8125rem;
    }
}

/* =============================== rightbar */
.FeedRightBar {
    display: none;
    /* margin-left: auto; */
    /* max-width: 280px;
      position: sticky !important;
      right: 0; */
}

@media (min-width: 1600px) {
    .FeedRightBar {
        display: block;
    }
}

/* ================================ feed list */
.FeedList {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    /* margin-bottom: 50px;
      margin-left: 350px; */

    /* overflow: auto;
      max-height: calc(100vh - 150px);
      scrollbar-width: none; */
}

.FeedList .error {
    min-height: 500px !important;
}

.FeedItemLayout {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(125, 125, 141, 0.16);
    /* margin: 10px 0; */
    margin-bottom: 20px;
    overflow: hidden;
    padding-bottom: 15px;
}

@media (min-width: 1200px) {
    .FeedItemLayout {
        width: 690px;
    }
}

@media (min-width: 1600px) {
    .FeedItemLayout {
        width: 550px;
    }
}

.FeedItemTopBar,
.FeedItemUsr {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    cursor: pointer;
}

.FeedItemUsr {
    margin-left: 10px;
}

.FeedItemUsr:hover .FeedItemUsrName {
    text-decoration: underline;
}

.FeedItemTopBar {
    padding: 17px 0px;
    background-color: white;
}

.FeedItemUsrDp {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
}

.FeedItemUsrDpImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.FeedItemUsrDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    gap: 3px;
}

.FeedItemUsrName {
    font-size: 13px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.FeedItemUsrTime {
    font-size: 11px;
    font-weight: 400;
    color: rgba(125, 125, 141, 1);
}

.feeditemmessage {
    font-size: 12px;
    font-weight: 400;
    color: rgb(90, 90, 90);
}

.FeedItemDropdown {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 50%;
    height: auto;
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
}

.FeedItemDropdown .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    min-height: 30px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* padding-top: 8px; */
}

.FeedItemDropdown .feedoptions {
    width: 200px;
}

.FeedItemDropdown .feedoptions .feedoptiontrianglecontainer {
    width: 200px;
    right: 12px;
}


.FeedItemDropdown span:hover {
    background-color: var(--cl_light_orange);
}

.feedoptions {
    position: absolute;
    right: 0;
    top: 50px;
    width: 300px;
    height: auto;
    z-index: 1000;
    border: 1px solid rgb(233, 233, 233);
    background-color: white;
    padding: 5px 5px;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.feedoptions .feedoptiontrianglecontainer {
    position: relative;
    display: block;
    height: 0;
    width: 290px;
    /* border: 1px solid blue; */
}

.feedoptions .feedoptiontrianglecontainer span {
    position: absolute;
    top: -11px;
    right: 3px;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    background-color: white;
    border-left: 1px solid rgb(233, 233, 233);
    border-top: 1px solid rgb(233, 233, 233);
}

.feedoptions li {
    font-size: 0.9375rem;
    text-decoration: none;
    list-style: none;
    height: 40px;
    padding: 9px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 5px;
}

.feedoptions li:hover {
    background-color: var(--cl_light_orange);
}





/*############"3rd"############# */
.FeedItemImageContainer3 {
    display: grid;
    gap: 1px;
    grid-template-columns: auto 33%;
    grid-template-rows: 50% 50%;
}

.FeedItemImageContainer3 .feedItemImageWrapper {
    height: 100%;
    width: 100%;
}

.FeedItemImageContainer3 .feedItemImageWrapper .feedItemImage1 {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.FeedItemImageContainer3 .feedItemImageWrapper:nth-child(1) {
    grid-row-start: row1-start;
    grid-row-end: 1;
}

.FeedItemImageContainer3 .feedItemImageWrapper video {
    height: 100%;
}

/*############"4th"############# */
.FeedItemImageContainer4 {
    display: grid;
    gap: 1px;
    grid-template-columns: 34% 33% 33%;
    grid-template-rows: 50% 50%;
}

.FeedItemImageContainer4 .feedItemImageWrapper {
    height: 100%;
    width: 100%;
}

.FeedItemImageContainer4 .feedItemImageWrapper .feedItemImage1 {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.FeedItemImageContainer4 .feedItemImageWrapper:nth-child(1) {
    grid-row-start: row1-start;
    grid-row-end: 1;
}

.FeedItemImageContainer4 .feedItemImageWrapper:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: col2-start;
    grid-row-start: 1;
    grid-row-end: 1;
}

.FeedItemImageContainer4 .feedItemImageWrapper video {
    height: 100%;
}

/*############"5th"############# */

.FeedItemImageContainer5 {
    display: grid;
    gap: 1px;
    grid-template-columns: auto 33% 33%;
    grid-template-rows: 50% 50%;
}

.FeedItemImageContainer5 .feedItemImageWrapper {
    height: 100%;
    width: 100%;
}

.FeedItemImageContainer5 .feedItemImageWrapper .feedItemImage1 {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.FeedItemImageContainer5 .feedItemImageWrapper:nth-child(1) {
    grid-row-start: row1-start;
    grid-row-end: 1;
}

/* .FeedItemImageContainer5 .feedItemImageWrapper:nth-child(5) {
          opacity: 0.5;
        } */
.FeedItemImageContainer5 .feedItemImageWrapper video {
    height: 100%;
}

.lencount {
    display: block;
    background-color: rgba(0, 0, 0, 0.39);
    box-shadow: 0px 0px 30px rgb(0, 0, 0);
    border-radius: 50%;
    font-size: 34px;
    /* font-weight: bold; */
    position: absolute;
    bottom: 16%;
    right: 13%;
    color: white;
}

.lencountactive {
    display: block;
}

.lencountinactive {
    display: none;
}

.FeedItemEventFooterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    /* margin: 22px 25px; */
    padding: 22px 25px 25px 25px;
    /* padding-bottom: 20px; */
    border-bottom: 1px solid rgba(125, 125, 141, 0.16);
}

.FeedItemEventFooterContainer p {
    font-size: 14px;
    font-weight: 400;
}

.FeedItemEventFooter {
    display: flex;
    /* flex-direction: column; */

}

.FeedItemEventDp {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.FeedItemEventName {
    font-weight: 600;
    font-size: 18px;
}

.FeedItemEventTime {
    font-size: 12px;
    font-weight: 400;
    color: rgba(125, 125, 141, 1);
}

.FeedItemIconShareDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    /* margin: 0 20px 10px 20px; */
    padding: 10px 25px 10px 25px;
}

.FeedItemIconDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    width: 50%;
    min-height: 30px;
}

.FeedItemIconDiv div {
    display: flex;
    /* justify-content: center; */
    align-content: center;
    gap: 3px;
    height: 25px;
    min-height: 25px;
}

.FeedItemIconDiv div svg {
    height: 17px;
    min-height: 17px;
    min-width: 17px;
}

.FeedShareDiv {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 3px;
    cursor: pointer;
}

/* .FeedShareDiv:hover > .FeedShareDiv svg{
          background-color: var(--cl_light_grey);
        } */

.FeedShareDiv svg {
    height: 17px;
    min-height: 17px;
    min-width: 17px;
    /* border-radius: 50%; */
}

.FeedItemDivCommentBox {
    display: flex;
    flex-direction: column;
}

.FeedItemCommentBox {
    padding: 5px 0px;
    margin: 0 50px;
    display: flex;
    width: calc(100% - 50px);
    /* flex-direction: row; */
    /* grid-template-columns: 32px 1fr 30px; */
    /* flex-direction: row; */
}

.CommentReplyBox {
    /* border: 1px solid black; */
    display: grid;
    grid-template-columns: 32px 1fr 30px;
    margin: 5px 0 0 10px;
    width: 100%;
}

/* .disappear {
          display: none;
        }
        .noComments,
        .noReplies {
          display: none;
        }
        .appear {
          display: block;
        } */
.Like,
.Replyicons {
    font-size: 11px;
    color: rgb(0, 0, 0, 0.8);
    cursor: pointer;
    background-color: white;
    /* border: 1px solid black; */
}

.Replyicons {
    display: flex;
    gap: 3px;
}

.Replyicons svg {
    transform: translateY(3px);
}

.ResponseBox {
    display: flex;
    gap: 10px;
    padding: auto 0;
    /* border: 1px solid black; */
}

.allReplies {
    display: block;
    font-size: 11px;
    color: rgb(143, 142, 142);
    cursor: pointer;
}

.allComments {
    padding-left: 30px;
    display: block;
    font-size: 14px;
    color: rgb(148, 148, 148);
    cursor: pointer;
}

.commentIcons {
    height: 11px;
    /* border: 1px solid red; */
}

.FeedItemReplyBox {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding-bottom: 30px; */

    margin: 5px 0 5px 0;
    /* border: 1px solid black; */
}

.FeedItemReplyDp {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FeedItemReplyInputDiv {
    width: 85%;
    height: 40px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--cl_light_grey);
    padding: 4px;
    border-radius: 10px;
}

.FeedItemReplyInput {
    border: none;
    background-color: transparent;
    width: 80%;
    outline: none;
    padding-left: 8px;
}

.FeedItemReplyBtn {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    width: 75px;
    gap: 4px;
    border-radius: 7px;
    letter-spacing: 0.03em;
}

/* v2 */
.feedcontentcontainer {

    width: 100%;
    /* max-height: calc(100vh - 220px); */
    max-height: 614px;
    overflow: hidden;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.feedcontentwrapper1 {
    /* width: 100%;
    min-height: 200px; */
    overflow: hidden;
    /* min-height: 200px; */
    /* height: inherit; */
    /* object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; */


    display: grid;
    gap: 1px;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    max-height: 614px;
    align-content: center;
}

/* .feedcontentwrapper div{
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    
  }
  
  .feedcontentwrapper div video{
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
  } */
/* .FeedItemImageContainer2 {
    display: grid;
    gap: 1px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
  } */
.feedItemImageWrapper {
    overflow: hidden;
}

.feedItemImageWrapper video {
    /* height: 100%;
    width: auto; */
    width: 100%;
    min-height: 614px;
}

.videooverlayicon:hover svg {
    transition: 0.4s ease-in-out;
    transform: scale(1.15);
}

.imagewrapper {
    /* width: 100%; */
    /* min-height: 200px; */
}


.FeedItemImageContainer {
    position: relative;
    width: 100%;
    height: auto;
    /* min-height: 440px; */
    /* max-height: 440px; */

    /* max-height: 614px; */
    max-height: calc(100vh - 200px);

    overflow: hidden;
    /* margin: 10px auto; */
}

@media (max-height: 900px) {
    .FeedItemImageContainer {
        max-height: 60vh;
    }

    /* .FeedList {
      margin-left: 19vw;
    } */
}

.videooverlayicon {
    width: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedvideoicon {
    /* display: flex;
      align-content: center; */
    /* padding-left: 5px; */
    font-size: 54px;
    background-color: var(--cl_orange);
    color: white;
    border: none;
    border-radius: 50%;

    /* cursor: pointer; */
}

@media (min-width: 1850px) {
    .FeedItemLayout {
        width: 690px;
    }

    /* .FeedList {
        margin-left: 19vw;
      } */
}

/*############"first"############# */
.one {
    /* max-height: 614px; */
    max-height: calc(100vh - 200px);


    /* display: grid;
      height: auto; */
    /* max-height: 600px !important; */
}

.one .onevid {
    width: 100%;
    /* max-height: 614px; */
    max-height: calc(100vh - 200px);
    display: flex;
    align-content: center;
    justify-content: center;
    /* background-color: rgb(249, 249, 249); */
    background-color: black;

}

.oneimage {
    /* max-height: 614px; */
    max-height: calc(100vh - 200px);
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.oneimage img {
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    width: 100%;
}

.oneimage .img {
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    width: 100%;
}

.FeedItemImageContainer1 .feedItemImageWrapper {
    height: 100%;
    width: 100%;
    background-color: rgb(243, 243, 243);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* object-fit: contain; */
}

/*############"scnd"############# */
.FeedItemImageContainer2 {
    display: grid;
    gap: 1px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}

.FeedItemImageContainer2 .feedItemImageWrapper {
    height: 100%;
    width: 100%;
}

.FeedItemImageContainer2 .feedItemImageWrapper .feedItemImage1 {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.FeedItemImageContainer2 .feedItemImageWrapper video {
    height: 100%;
}








































.feedslider {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 90vh;
    width: 80vw;
    max-width: 80vw;
    max-height: 90vh;
    margin: 0 auto;
}

video .clickToPlay {
    background-color: var(--cl_orange) !important;
    color: white !important;
}

.feedslide {
    opacity: 0;
    transition-duration: 1s ease;
    transform: scale(.9);
}

.feedslide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1);
}

.slideleft,
.slideright {
    position: absolute;
    cursor: pointer;
    color: rgb(252, 245, 245, 0.3);
    z-index: 10;
}

.slideleft {
    left: 0;
}

.slideright {
    right: 0;
}

.disappear {
    display: none;
}

.noComments,
.noReplies {
    display: none;
}

.appear {
    display: block;
}

.cover {
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    width: 100%;
}

.contain {
    width: 100%;
    object-fit: contain;
    -o-object-fit: contain;
    object-position: contain;
    -o-object-position: contain;
}

@keyframes example {
    0% {
        height: auto;
        overflow: hidden;
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        height: 0;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transform: translateX(100%);
        display: none;
    }
}

@keyframes example1 {
    0% {
        height: auto;
        overflow: hidden;
        opacity: 1;
        transform: translateX(0);
    }

    90% {
        height: 0;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transform: translateX(100%);

    }

    100% {
        display: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.fadeout {
    animation: example 1s normal forwards ease-in-out;
    /* animation: example 2s linear 0.5s 1 normal forwards; */
}

.fadeout1 {
    animation: example1 1s normal forwards ease-in-out;
    /* animation: example 2s linear 0.5s 1 normal forwards; */
}

.page {
    position: absolute;
    left: 15px;
    right: 15px;
}

.page-enter {
    opacity: 0;
    transform: scale(1.03);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.97);
    transition: opacity 300ms, transform 300ms;
}

.feedlikedcontainer {
    display: flex;
    padding-bottom: 10px;
}

.feedlikedimages {
    display: flex;
    margin-left: 25px;
    max-width: 58px;
}

.feedlikedimages img {
    border: .5px solid white;
}

.feedlikeimage0 {
    z-index: 5;
}

.feedlikeimage1 {
    transform: translateX(-14px);
    z-index: 4;
}

.feedlikeimage2 {
    transform: translateX(-28px);
    z-index: 3;
}

.feedlikenames {
    font-size: 13px;
    padding: 5px;
}

.feedlikenames:hover {
    text-decoration: underline;
}

.feedlikenames small {
    font-size: 13px;
}

.feedlikeimage {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

@keyframes feedlike {
    0% {
        border-image-source: linear-gradient(to right, #FF5917, #AC17FF);
    }

    25% {
        border-image-source: linear-gradient(to right, #AC17FF, #FF5917);
    }

    50% {
        border-image-source: linear-gradient(to right, #FF5917, #AC17FF);
    }

    75% {
        border-image-source: linear-gradient(to right, #AC17FF, #FF5917);
    }

    100% {
        border-image-source: linear-gradient(to right, #FF5917, #AC17FF);
    }
}

.hasttags small {
    color: cyan;
}