.miniEventTab {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    justify-content: space-around;
    align-items: center;
}

.eventTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    justify-content: space-evenly;
    height: 135px;
    width: 98px;
    border-radius: 12px;
    border: 1px solid var(--cl_light_grey);
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.eventTab:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.eventTab h5 {
    color: black;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    font-weight: 600;
}

.eventTab span {
    color: black;
    transition: all 0.3s ease-in-out;
    font-size: 11px;
}

.eventTabIcon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var(--cl_light_orange);
    transition: all 0.3s ease-in-out;
}

.eventTabActive {
    background-color: var(--cl_orange);
}

.eventTabActive .eventTabIcon {
    background-color: white;
}

.eventTabActive h5 {
    color: white;
}

.eventTabActive span {
    color: white;
}






































.singleEventPopularity {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid green; */
}

.singleEventPopTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 135px;
    width: 98px;
    border-radius: 20px;
    border: 1px solid var(--cl_light_grey);
    cursor: pointer;
}

.singleEventPopTab:hover {
    background-color: var(--cl_light_orange);
    color: var(--cl_orange);
}

.singleEventPopTabActive {
    background-color: var(--cl_orange);
    color: white;
    cursor: default;
    pointer-events: none;
}

.singleEventFooterIcon {
    border-radius: 50%;

}

.singleEventFooterIconActive {
    background-color: white;
}

.featuredProfilesTitleActive,
.featuredProfileUsrnameActive {
    color: white;
}