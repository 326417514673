



.react-datepicker{
    border: 1px solid var(--cl_light_grey);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    padding: 10px 0 !important;
}

.react-datepicker__day{
    font-size: .8rem;
    border: .5px solid var(--cl_lightgrey);
    border-radius: 50%;
}

.react-datepicker__day--selected{
    background-color: var(--cl_orange) !important; 
    border-radius: 50% !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 2rem;
    height: 2rem;

    padding: .2rem 0;
}
.react-datepicker__header{
    background-color: white;
    border: none;
    outline: none;
}
.react-datepicker__day-name{
    color: var(--cl_textgrey) !important;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover{
    background-color: var(--cl_orange) !important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    border-radius: 50% !important;
}
.react-datepicker__current-month{
    font-size: 1.1rem;
}
.react-datepicker__navigation{
    top: 10px;
}
.react-datepicker__day--outside-month{
    background-color: var(--cl_lightgrey);
    border-radius: 50%;
}
.react-datepicker__week{
    padding: 2px;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__day--keyboard-selected{
    background-color: var(--cl_light_orange) !important;
}
.react-datepicker__day--keyboard-selected{
    color: black;
}
.react-datepicker__navigation--previous{
    left: 10px !important;
    top: 15px;
}
.react-datepicker__navigation--next{
    right: 10px;
    top: 15px;
}
.select .react-datepicker-popper{
    
    transform: translateX(0px) !important;
}

.react-datepicker{
    width: 300px;
    border-radius: 12px !important;
    padding: 5px 0 12px 0;
}
.select .react-datepicker{
    width: 300px;
    border-radius: 12px !important;
    padding: 5px 0 12px 0;
}
.select .react-datepicker__month-container{
    width: 100%;
    height: 100%;
}
.react-datepicker__month-container{
    width: 100%;
    height: 100%;
}


.react-datepicker__triangle{
    transform: translate(50px) !important;
}
.react-datepicker__triangle::after{
    border-bottom-color: white !important;
}
.react-datepicker__triangle::before{
    border-bottom-color: var(--cl_lightgrey) !important;
}

.react-datepicker__input-container input{
    height: 40px;
    width: 100% !important;
    /* margin-left: 58px; */
    padding: 0 10px !important;
    border: none;
    outline: none;
    background-color: var(--cl_bggrey);
    border-radius: 8px;
    font-family: "Inter", sans-serif;
}

.dateandtime_wrapper .react-datepicker__input-container input{

    height: 40px;
    width: 100% !important;
    max-width: 255px !important;
    /* margin-left: 58px; */
    padding: 0 10px !important;
    border: none;
    outline: none;
    background-color: var(--cl_bggrey);
    border-radius: 8px;
    font-family: "Inter", sans-serif;
}