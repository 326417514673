
.menu-container {
    position: relative;
    height: 100%;
    width: 100%;
}
.settingsDropdown {
    background: #ffffff;
    border-radius: 10px;
    top: 55px;
    right: -4px;
    min-width: 220px;   
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    padding: 8px;
}
@media (min-width: 1400px){
    .settingsDropdown{
        top: 55px;
        right: initial;
    }
}
.settingsDropdown span{ 
    position: absolute;
    top: -7px;
    right: 20px;
    display: block;
    height: 10px;
    width: 10px;
    background-color: white;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
}
@media (min-width: 1400px){
    .settingsDropdown span{
        left: 15px;
    }
}
.settingsDropdown.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
}
.settingsDropdown.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.settingsDropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.settingsDropdown li {
    font-size: var(--font-size-13);
    font-weight: 500;
    transition: background-color 0.2s ease-in-out;
    border-radius: 8px;
    /* padding: 15px 20px; */
    display: block;
}
.settingsDropdown li a {
    text-decoration: none;
    color: #040405;
    padding: 15px 20px;
    display: block;
    transition: color 0.2s ease-in-out;
}
.settingsDropdown li:hover {
    color: var(--cl_orange);
    background-color:var(--cl_light_orange);
    border-radius: 8px;

}
.settingsDropdown li:hover > a{
    color: var(--cl_orange);
}


    