/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */
  .loginPageLayout {
    height: 100vh;
    width: 100vw;
    display: flex;
    font-family: inherit;
  }
  .loginPageInputSection,
  .loginPageIntroSection {
    width: 50%;
    height: 100%;
    position: relative;
  }
  .loginPageInputSection {
    padding: 50px 0 50px 60px;
    max-height: 100vh;
    overflow: auto;
  }
  .loginPageInputSection::-webkit-scrollbar {
    width: 0;
  }
  
  .loginPageInputDiv {
    /* height: 90%; */
    width: 100%;
    height: 100%;
    margin: auto auto;
    display: grid;
    grid-template-rows: 48px 1fr;
    /* display: flex;
    flex-direction: column; */
    /* gap: 25px; */
    /* max-width: 500px; */
    /* padding-left: 50px; */
  }
  @media only screen and (min-width: 600px) {
    .loginPageInputDiv{
      width: 512px;
    }
  }
  .loginPageLogo {
    height: 48px;
    cursor: pointer;
    position: relative;
  }
  .loginPageLogo img {
    object-fit: contain;
    height: 100%;
    cursor: pointer;
  }
  .loginPageLogo .logomain{
    
  }
  .loginPageLogo .beta{
    position: absolute;
    top: -10px;
    /* right: -20px; */
    left: 65px;
  }

  /* .password {
      margin-top: 20px;
    } */
  .checkBox:checked {
    background-color: #ff5917;
    color: #ff5917;
  }
  .LoginBtnLinks {
    width: 100%;
    height: 40px;
  }
  .loginBtn {
    height: 100%;
    width: 100%;
    background-color: #ff5917;
    color: white;
    font-weight: bold;
    margin-top: 10px;
  }
  .loginBtn:active {
    outline: none;
    border: none;
    background-color: #ec4808;
  }


  .checkBoxContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 10px;
    font-size: 14px;
    color: rgb(165, 161, 161);
  }
  .checkBoxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: rgb(240, 236, 236);
    border: 0.5px rgb(80, 80, 80);
    border-radius: 3px;
  }
  
  .checkBoxContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .checkBoxContainer input:checked ~ .checkmark {
    background-color: #ff5917;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkBoxContainer input:checked ~ .checkmark:after {
    display: block;
  }
  .checkBoxContainer .checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  

  .signInDiv {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
  }
  .signInDiv p {
    padding-top: 2px;
    color: var(--cl_textgrey);
  }

  
  .loginPageIntroSection {
    overflow: hidden;
    background-color: #ff5917;
  }
  /* .loginPageIntroSection img{
  z-index: 1000;
  } */
  .loginPageIntroSection .loginbg {
    height: 100%;
    width: auto;
    position: absolute;
  
    filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
    /* transform: scale(1.1) translateY(100px); */
  }
  .loginPageIntroSection .logobg1{
    /* width: 204.34px;
    height: 256.17px; */
    width: 145px;
    height: 257px;
  /* transform: rotate(180deg); */
  position: absolute;
  right: 0;
  bottom: 20vh;
  }
  .loginPageIntroSection .logobg2{
  /* height: 222px;
  width: 214px; */
  height: 223px;
  width: 164px;
  position: absolute;
  left: 0;
  top: 10vh;
  }
  .loginPageIntroSection .logobg3{
    /* width: 229.5px;
    height: 212px; */
    width: 230px;
    height: 163px;
    position: absolute;
    right: 60px;
    top: 0;
    
  }
  .introOverlay {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    /* background-color: #ff5917; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .overlayContentDiv {
    height: 800px;
    width: auto;
    min-width: 400px;
    max-width: 600px;
  
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    outline: none;
    z-index: 1050;
  }
  .introImgDiv {
    margin: 0 auto;
    object-fit: cover;
  
    overflow: hidden;
    height: 410.5px;
    width: 323.5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(0.9);
    z-index: 2000;
    /* transform: scale(0.35); */
  }
  
  .overlayContentDiv h4 {
    margin-top: 50px;
    font-size: 1.2rem;
  }
  .overlayContentDiv p {
    font-size: 0.9rem;
    padding: 0 30px;
    color: rgb(218, 216, 216);
    margin-top: 10px;
  }
  /* .cetQhm {
    background-color: white !important;
    box-shadow: none !important;
  }
  
  .bQjoUN {
    background-color: rgb(214, 212, 212) !important;
    box-shadow: none !important;
    transform: scale(0.7);
    outline: none !important;
  } */
  
  /* .signupLabel,
  .signupPassword {
    margin-top: 10px;
  } */
  .signup svg{
    margin-top: 30px;
  }
  .signupHeading {
    font-weight: 600;
    font-size: 48px;
    margin-top: 50px !important;
  }
  .signUpForm {
    display: flex;
    flex-direction: column;
    gap: 5px !important;
  }
  .signUpForm input {
    height: 40px;
    width: 512px;
    outline: none;
    border: none;
    border-radius: 5px;
    padding-left: 15px;
  }
  
  /* validate */
  .loginmailvalidate,
  .loginpasswordvalidate,
  .forgetmailvalidate,
  .otpvalidate {
    visibility: hidden;
    pointer-events: none;
    margin-left: 5px;
  }
  .Signupvalidate {
    visibility: hidden;
  }
  
  .validateerror {
    color: red;
    visibility: visible;
    pointer-events: none;
    margin-left: 5px;
  }
  .validatesuccess {
    color: green;
    visibility: visible;
    pointer-events: none;
    margin-left: 5px;
  }
  .validatewarning {
    color: rgb(241, 162, 43);
    visibility: visible;
    pointer-events: none;
    margin-left: 5px;
  }
  .signupLabelDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  @media only screen and (min-width: 1200px) {
    .overlayContentDiv {
      /* position: relative; */
      /* left: 15%; */
      margin-left: auto;
      margin-right: auto;
      /* border: 3px solid rgb(255, 0, 200); */
    }
  }
  .swiper {
    /* position: relative; */
    /* top: 30%; */
    /* border: 1px solid black; */
    width: 100%;
  }
  
  /* .swiper .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    top: 0 !important;
  } */
  
  .swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: white;
  }
  
  /* newlogin */
.lgcontainer{
  display: flex;
  align-items: center;
}
  .lg{
    /* padding: 32% 0 35% 0; */
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .lg h1 {
    font-weight: 600;
    font-size: 50px;
    /* margin-top: 168px; */
    margin-bottom: 22px;
  }
  .lg p {
    color: #7D7D8D;
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
  .lg form {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .lg form input,
  .lgbtn {
    height: 48px;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 100%;
  }
  .lglabel{
    font-size: 13px;
    font-weight: 400;
    margin-top: 22px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  .lg .rememberDiv {
    display: flex;
    align-content: center;
  }
  .rememberDiv{
    margin: 10px 0 20px 0;
  }
  .rememberDiv input{
    display: none;
  }
  .rememberDiv p{
    pointer-events: none;
    -webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
  }
  .rememberDiv p,
  .rememberDiv a {
    align-self: center;
    padding-left: 8px;
  }
  .signInDiv a{
    color: var(--cl_orange);
  }
  .rememberDiv a{
    margin-left: auto;
    color: #ff5917;
    /* font-size: 0.875rem; */
    font-size: 0.8rem;
    font-weight: 400;
    transition: 0.3s ease-in;
  }
  .rememberDiv a:hover,.lgsignin a:hover,  .signInDiv a:hover{
    color: #fc5513;
    font-weight: 500;
  }

  .lg form input {
    background-color: var(--cl_lightgrey) !important;
    padding-left: 15px;
  }
  .lg form input::placeholder {
    color: rgb(165, 161, 161);
  }
  .lgbtn{
    background-color: var(--cl_orange) !important;
    color: white;
    font-size: 0.875rem;
    font-weight: 400;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 8px;
  }
  .lgbtn:hover{
    background-color: #fc5513;
    /* font-size: 1rem; */
    font-weight: 500;
  }
  .lgbox{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: .5px solid #8693a046;
    border-radius: 5px;
    /* background-color: var(--cl_lightgrey); */

    transition: 0.3s ease-in-out;
  }
  .checked{
    background-color:  var(--cl_orange);
    border: .5px solid var(--cl_orange) !important;
  }
  .lgsignin{
    display: flex;
    justify-content: center;
    margin-top: 15px;


  }
  .lgsignin p{
    font-size: .9rem;
    margin-right: 5px;
  }
  .lgsignin a{
    color: var(--cl_orange);
    font-weight: 400;
    font-size: .9rem;
  }
  .inputerror{
    background-color: rgba(255, 0, 0, 0.308);
    border: 1px solid red;
  }
  @media only screen and (max-width: 1300px) {
    .loginPageIntroSection{
      display: none;
    }
    .loginPageInputSection{
      width: 100%;
      padding: 50px 60px;
    }
  }
  .fg{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: relative;
  }
  .fg svg{
    position: absolute;
    top: 15px;
  }
.forgotpassword{
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.forgotpassword h1{
  font-size: 40px;
  font-weight: 600;
  /* margin-top: 130px; */
  /* margin-bottom: 30px; */
}
.forgotpassword p{
  color: var(--cl_textgrey);
  font-size: .9rem;
}
.forgotpassword form{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
}
.forgotpassword input{
  height: 48px;
  width: 512px;
  padding-left: 15px;
  border-radius: 8px;
  background-color: var(--cl_lightgrey);
  border: none;
  outline: none;
}
.verificationsent{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.verificationsent h1{
  margin-top: 30%;
  
}



.errorMessage{
  padding: 10px 15px;
  background-color: rgba(255, 0, 0, 0.04);

}
.errorMessage p{
  color: red;
  /* font-size: ; */
}
/* .forgotpassword .lgbtn{
  margin-top: 20px;
} */
.loginpasswordwrapper{
  position: relative;
}

.bishow_login{
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bihide_login{
  color: grey;
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input::-ms-clear, input::-ms-reveal {
  display: none;
}