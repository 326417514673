.notificationPage{
    /* min-height: 100vh; */
    position: relative;
}
.notificationPage .notificationItem{
  background-color: var(--cl_bg-grey);
  border-radius: 8px;
}
.notificationPageWrapper{
    width: 100%;
    max-width: 688px;
    /* position: fixed;
    top: 152px; */
}
.notificationPage .commonHeading{
    max-width: 688px;
    /* position: sticky; */
    top: 152px;
    /* background-color: white; */
}
.notificationsIcons{
    display: -webkit-flex;
    display: flex;
    gap: 8px;
    margin-left: auto;
}
.notificationSettingsIcon{
    fill: none;
    cursor: pointer;
}
/* .notificationSettingsIcon:hover > circle{
    fill: var(--cl_bg-grey);
} */
.notificationSettingsIcon circle{
    fill: white;
    stroke: #E2E2F0;
    transition: fill 0.3s ease-in-out;
}
.notificationSettingsIcon path{
    stroke: #040405;
    transition:stroke 0.3s ease-in-out;
}
.notificationSettingsIcon:hover > circle {
    fill: var(--cl_orange);
    stroke: none;
}
.notificationSettingsIcon:hover > path {
    stroke: white;
}
.clearbtn{
    fill: none;
    transition: all 0.3s ease-in-out;
  }
  .clearbtn circle{
    fill:#FF1717;
    fill-opacity: 0.1;
    transition: all 0.3s ease-in-out;
    /* stroke: #FF1717; */
  }
  .clearbtn path{
    stroke: #FF1717;
    transition: all 0.3s ease-in-out;
  }
  .clearbtn:hover > circle{

    fill-opacity: 0.9;
  }
  .clearbtn:hover > path{
    stroke: white;
  }

  .refreshbtn{
    fill: none;
    transition: all 0.3s ease-in-out;
  }
  .refreshbtn circle{
    fill: white;
    stroke: #E2E2F0;
    transition: all 0.3s ease-in-out;
  }
  .refreshbtn path{
    stroke: #040405;
    transition: all 0.3s ease-in-out;
  }
  .refreshbtn:hover > circle{
    fill: var(--cl_orange);
    stroke: none;
  }
  .refreshbtn:hover > path{
    stroke: white;
  }
  .notificationList{
      max-width: 688px;
      margin-top: 32px;
      /* min-height: 200vh; */
      display: flex;
      flex-direction: column;
      gap: 8px;

  }