.discoverSearchDiv{
    background-color: #212025;
    border-radius: 12px;
    width: 100%;
    min-height: 164px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 32px;
}
.discoverSearchDiv h2{
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
}
.discoverSearchDiv .mainBtn{
    display: flex;
    gap: 5px;
    align-items: center;
}
.mainBtn svg path{
    stroke: white;
}
.select{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
}
.select button{
  margin-top: 15px;
  justify-content: center;

}
.select button{
  grid-column-start: 1;
  grid-column-end: col2-end;
  /* grid-row-start: 3;
  grid-row-end: 3; */
}
.select .datepickerwrapper{
  grid-column-start: 1;
  /* grid-column-end: col1-end; */
}

@media (min-width: 850px) {
  .select{
  /* display: flex;
  flex-direction: row;
  gap: 6px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 106px;
  gap: 6px;
}
.select .datepickerwrapper{
  grid-column-start: 3;
}
/* .select .discoverInput{
  grid-column-start: 1;
} */
.select button{
  margin-top: 0px;
  grid-column-start: 5;
  grid-column-end: col5-end;
}

}
.inputgrid{
  /* grid-template-columns: 1fr 40px; */
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: rgb(51, 53, 56);
  border-radius: 5px;
}
.inputgrid svg{
  position: relative !important;
}
.discoverInput{
    height: 40px;
    background-color: rgb(51, 53, 56);
    border: none;
    outline: none;
    border-radius: 5px;
    color: white;
    padding-left: 8px;
    min-width: 100px;
    width: 100%;
    font-family: "Inter", sans-serif;
}

.select .datepickerwrapper{
  min-width: 130px;
}

.select .react-datepicker-wrapper input{
    background-color: rgb(51, 53, 56) !important;
    color: white !important;
    border: none;
    outline: none;
    height: 40px;
    padding-left: 8px;
    min-width: 100px;
    width: 100%;
    border-radius: 5px;
    font-family: 'Inter',sans-serif;

  }
  .select .react-datepicker__tab-loop{
    /* display: none; */
    position: absolute;
    /* height: 0 !important;
    width: 0 !important; */
  }
  .select .react-datepicker__tab-loop div{
  position: relative;
  }
  .datepickerwrapper{
    position: relative;
  }
  .datepickerwrapper svg{
    position: absolute;
    right: 10px;
    top: 13px;
    pointer-events: none;
  }
.suggestions{
  color: white;
}
