
    
.notificationDropdown .notificationItem{
    border: .5px solid var(--cl_lightgrey);
    max-width: 100%;
}
.notificationPage  .notificationItem:hover{
    background-color: #c4e2f5;
}
.notificationItem:hover{
    background-color: #c4e2f5;
}
.notificationItem{
    min-height: 90px;
    width: 100%;
    max-width: 688px;
    padding: 16px 24px;
    display: grid;
    grid-template-columns: 36px 1fr minmax(64px,126px);
    gap: 18px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.notificationDp img{
    height: 36px;
    width: 36px;
    background-color: var(--cl_light_grey);
    border-radius: 50%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.notificationImage,.notificationImage1 {
    display: flex;
    flex-direction: row-reverse;
    /* align-items: flex-end;
    justify-content: flex-end; */
}
.notificationImage img, .notificationImage1 img{
    height: 64px;
    width: 64px;
    min-height: 64px;
    min-width: 64px;
    background-color: var(--cl_light_grey);
    border-radius: 8px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border: 1px solid var(--cl_light_grey);
}
/* .notificationImage img:nth-child(1){
    transform: translateX(-20px);
} */
.notificationImage img:nth-child(2),.notificationImage1 img:nth-child(2){
    transform: translateX(30px);
}
.notificationImage img:nth-child(3), .notificationImage1 img:nth-child(3){
    transform: translateX(60px);
}
.notificationImage1 img{
    border-radius: 50%;
}
/* .notificationDetails{

} */
.notificationDetails h4{
    font-size: 16px;
}
.notificationDetails p{
    font-size: 14px;
    /* white-space: break-spaces;
    overflow: hidden;
    max-width: 250px; */
}
.notificationDropdown .notificationDetails p{

    white-space: break-spaces;
    overflow: hidden;
    max-width: 250px;
}
.notificationDetails span{
    font-size: var(--font-size-13);
    color: var(--cl_textgrey2);
}

.userNotificationConnectionButtons {
    /* position: relative; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    width: 100%;
  }

.closeroundedloading, .doneroundedloading{
    height: 32px;
    max-height: 39.7px;
    width: 90px;
    border-radius: 7px;
    /* display: flex; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;


}
.closeroundedloading, .doneroundedloading{
    width: 52px;
}
.notificationDropdown .closeroundedloading, .notificationDropdown .doneroundedloading{
    width: 42px;
}
.closeroundedloading svg, .doneroundedloading svg{
    cursor: pointer;
}
.closeroundedloading{
    color: var(--cl_orange);
    border: .5px solid var(--cl_lightgrey);
    background-color: white;
}
.doneroundedloading{
    background-color: var(--cl_orange);
color: white;
}
.closeroundedloading:hover{
    background-color: var(--cl_lightgrey);
    border: .5px solid rgb(207, 207, 207);
}
.doneroundedloading:hover{
    background-color: #ff4800;
}