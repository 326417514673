.switchprofilemodal li{
    transition: background-color 0.2s ease-in-out;
    border-radius: 8px;
    /* padding: 15px 20px; */
    display: block;
    padding: 12px 16px;
}
.switchprofilemodal .profileslistitem {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 16px;
}
.switchprofilemodal li img{
    width: 48px;
    height: 48px;
    border-radius: 12px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.switchprofilemodal li h5{
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
}
/* .switchprofilemodal li a {
    text-decoration: none;
    color: #040405;
    padding: 15px 20px;
    display: block;
    transition: color 0.2s ease-in-out;
} */
.switchprofilemodal .profileslistitem:hover {
    color: var(--cl_orange);
    background-color:var(--cl_light_orange);
    border-radius: 8px;

}
.switchprofilemodal li .profilelistitemcontent{
    display: grid;
    grid-template-columns: 1fr 24px;
    gap: 5px;
    align-items: center;
}
.profilelistitemdetails{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    
}
.switchprofilemodal li div small{
    font-size: 12px;
    color: #7D7D8D;
}
.switchprofilemodal li small{
    font-size: 13px;
    color: #7D7D8D;
    
}

/* .switchprofilemodal li:hover > a{
    color: var(--cl_orange);
} */

.roundedcheckbox{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #E2E2F0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.roundedcheckboxactive{
    background-color: var(--cl_orange);
}
.roundedcheckboxdot{
    background-color: white;
    height: 7px;
    width: 7px;
    border-radius: 50%;
}


