.ticketing_portal_report_section {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.reports_dropdown {
    height: 30px;
    width: 150px;
    background-color: lightblue;
    border: none;
    border-radius: 5px;
    justify-content: flex-end;
    margin-left: 30px;
}

.reports_dropdown_section {
    padding: 5px;
    display: flex;
    width: 90%;
    flex-direction: row-reverse;
    margin-top: 10px;
    /* align-items: flex-end; */
    /* justify-content: flex-end; */
    margin-bottom: 15px;
    margin-left: 30px;
}

.cm_detail_table {
    width: 100%;
}

.cm_table_head {
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
}

.cm_table_head li {
    width: 100%;
}

.report_body {
    display: flex;
    align-items: flex-start;
    padding: 7px;
    justify-content: space-between;
    border-bottom: solid 1px rgb(227, 235, 238);
}

.report_body tr {
    width: 100%;
}

.report_name_section {

    display: flex;
    gap: 10px;
    align-items: center;
}

.top_revenue_main {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.top_revenued_datepickerwrapper {
    display: flex;
    align-items: flex-end;
    justify-items: flex-end;
    gap: 10px;
    /* border: solid 1px; */
}

.top_revenued_from_date {
    /* background-color: rgb(230, 237, 240); */
    display: flex;
    align-items: center;
}

.top_revenued_to_date {
    display: flex;
}

.date_picker_calendaricon {
    display: flex;
}

.top_revenue_detail_table {
    width: 100%;
    border-radius: 5px;
    /* min-height: 200px;
    max-height: 30px; */
    border: solid 1px lightgray;
}

.top_revenue_detail_table_head {
    display: flex;
    height: 40px;
    background-color: lightgray;
    justify-content: space-between;
    align-items: center;
    justify-items: flex-start;
    /* align-items: flex-start; */
    padding: 7px;
    border-radius: 5px;
}

.top_revenue_detail_table_head h5 {
    width: 100%;
}

.top_revenue_detail_table_body {
    display: flex;
    align-items: center;
    padding: 7px;
    justify-items: flex-start;
    justify-content: space-between;
    border-bottom: solid 1px rgb(227, 235, 238);
}

.top_revenue_detail_table_body div {
    width: 100%;
}

.top_revenue_detail_table_name_section {
    display: flex;
    gap: 10px;
    align-items: center;
}

.top_revenue_scrollbar {
    height: 300px;
}

.report_linechart {
    margin-bottom: 30px;
    width: 100%;
}

.revenue_generated_events_head {
    display: flex;
    /* gap: 5px; */
    justify-items: start;
    position: relative;
    align-items: center;
    gap: 5px;
    /* justify-content: flex-start; */
}

.dailySalesPopup {
    position: absolute;
    max-height: 50%;
    min-height: 30%;
    width: 40%;
    /* bottom: 0; */
    /* right: 30; */
    background-color: white;
    left: 38%;
    bottom: 20%;
    border: solid lightblue;
    border-radius: 12px;
    justify-self: center;
}

.popup-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    height: 90%;
    width: 100%;
}

.popup-content-heading {
    display: flex;
    justify-content: space-between;
    justify-items: flex-start;


}

.popup-content-heading h4 {
    display: flex;
    width: 100%;


}

.popup-content-body {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: lightgray;
    justify-items: flex-start;
    align-content: flex-start;


}

.popup-content-body div {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    align-items: center;



}

.popup-content-body p {
    display: flex;
    width: 100%;
    /* flex-direction: row;
    gap: 10px;
    align-items: center; */


}

.revenue_generated_events_head img {
    display: flex;
    border-radius: 50%;

    /* border: 1px solid; */

}


.dailysales_table {
    width: 100%;
    border-radius: 5px;
    /* min-height: 200px;
    max-height: 30px; */
    border: solid 1px lightgray;
}

.dailysales_table_head {
    display: flex;
    height: 40px;
    background-color: lightgray;
    justify-content: space-between;
    align-items: center;
    justify-items: flex-start;
    /* align-items: flex-start; */
    padding: 7px;
    border-radius: 5px;
}

.dailysales_table_head h5 {
    width: 100%;
}

.daily_sales_scrollbar {
    height: 300px;
}

.dailysales_table_body {
    display: flex;
    align-items: center;
    padding: 7px;
    justify-items: flex-start;
    justify-content: space-between;
    border-bottom: solid 1px rgb(227, 235, 238);
}

.dailysales_table_body div {
    width: 100%;
}

/* * {
    text-align: center;
} */

.main_popup {
    /* background-color: rgba(39, 145, 216, 0.710); */
    /* height: 100%;
    width: 100%; */
    position: absolute;
    top: 0;
    /* border: lightgray; */
}

.popup {
    height: 100px;
    width: 30%;
    /* margin-top: 500px;
    margin-left: 800px; */
    background-color: white;
    /* position: absolute; */
    margin-bottom: 100px;
    top: 25%;
    right: 25%;
    border: lightgray 1px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 15px;
    align-items: center;
    border-bottom: 2px solid black;
}

.arrow_dailysales RightArrow {
    background-color: aqua;
}