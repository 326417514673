/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ EXPLORE $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
.exploreDivLayout {
    display: flex;
    flex-direction: column;
    gap: 18px;
    /* align-items: flex-start;
    justify-content: space-around; */
    /* height: 160px; */
    width: 100%;
    /* margin: 20px 0; */

    /* border: 1px solid black; */
  }
  .exploreDivHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 80px;
    /* padding: 0 20px; */
    /* border: 1px solid red; */
  }
  .exploreDivTitle {
    color: black;
    font-weight: 600;
    display: flex;
    cursor: pointer;
    font-size: 1.5rem;
  
  }
  .exploreDivTitle span {
    color: black;
    font-weight: 600;
    font-size: 1.5rem;
    /* font-size: 24px; */
  }
  .exploreDivTitle svg {
    margin-top: auto;
    margin-left: 10px;
    font-size: 1.3rem;
    color: rgba(125, 125, 141, 1);
  }
  .exploreDivSubTitle {
    color: rgba(125, 125, 141, 1);
    font-size: 13px;
    padding-top: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .exploreSubDiv {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    padding: 3px 0 3px 3px;
  scroll-behavior: smooth;
  
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    white-space: nowrap;
    scrollbar-width: none;
    /* border: 1px solid yellow; */
    /* touch-action: pan-x;
  touch-action: pan-left;
  touch-action: pan-right;
  touch-action: pan-y; */
  }
  
  .exploreSubDiv::-webkit-scrollbar {
    display: none;
  }
  .exploreBoxDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 0.5px solid rgb(238, 238, 238);
    border-radius: 8px;
    align-items: center;
    width: auto;
    min-width: 200px;
    min-height: 65px;
    padding: 0px 15px;
    cursor: pointer;
    position: relative;
  
  }
  .exploreBoxDiv:hover {
    /* box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.089); */
    box-shadow: rgba(0, 0, 0, 0.062) 0px 2px 8px 0px;
  }
  .exploreBoxDivBorder {
    border: 1px solid var(--cl_orange);
  }
  .exploreBoxIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: var(--cl_orange);
    color: white;
    border-radius: 50%;
    margin-right: 1px;
  }
  .exploreBoxIcon img{
  width: 60%;
  object-fit: cover;
  }
  /* .embed{
    stroke: white;
  }
  .embed>path{
    stroke: white !important;
  } */
  .exploreBoxTitle {
    font-weight: 500;
  }
  .horizontal_indicator{
    position: absolute;
    right: 0px;
    height: 71px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .horizontal_indicator svg{
    animation: indicator .5s linear 0.5s infinite alternate;
  }
  @keyframes indicator {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(5px);
    }
  }