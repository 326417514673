.mediaModal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.808);
    z-index: 10000;
}
/* .carouselWrapper{
    width: 100%;
    height: 100%;
}
.carousel-slider, .slide{
    height: 100vh;
}
.slide{
    display: flex;
    justify-content: center;
} */
.carouselWrapper{
    width: 100%;
    height: 100%;
}
/* .carouselWrapper div{
    height: 100% ;
} */
/* .inviewinmediamodal>div{
    height: 100%;
} */
/* .carouselWrapper .carousel-slider,.carouselWrapper .carousel-root{
    height: 100% ;
}
.carouselWrapper .slider-wrapper{
    height: 100%;
} */
.imgtr{
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 150px);

    height: 100%;
}
.imgtr div{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-height: inherit;
    max-width: inherit; */

}
.react-transform-wrapper{
    height: 100%;
    width: 100%;
}
.imgtr img{
    object-fit: contain;
}
.slide,.slider-wrapper{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mediamodalWrapper{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10300;
}
.closeBtn{
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3 ease-in-out;
    z-index: 1000;
    /* background-color: var(--cl_orange); */
    cursor: pointer;
    /* background-color: rgba(0, 0, 0, 0.8); */
}
/* .closeBtn:hover{
    background-color: var(--cl_orange);
} */
/* .closeBtn:hover svg{
    color: white;
} */
.closeBtn svg{
    color: white;
}
.moreBtn{
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 10px;
    right: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3 ease-in-out;
    z-index: 1000;
    /* background-color: var(--cl_orange); */
    cursor: pointer;
}
/* .moreBtn svg:hover {
    background-color: var(--cl_orange);
} */

.carousel-status{
    font-size: 15px !important;
    left: 10px !important;
    top: 10px !important;
}
.control-arrow{
    width: 70px !important;
}
.control-next{
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
.control-prev{
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 10px solid transparent !important;
    border-bottom: 10px solid transparent !important;
    content: '';
}
.carousel .control-next.control-arrow:before {
    border-left: 10px solid #fff !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 10px solid #fff !important;
}




.mediamodaldetails{

    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mediamodalsectionwrapper{
    height: 90% !important;
    width: 90%;
    background-color: white;
    position: relative;
}
.carouselWrapper .mediamodalsectionwrapper{
    height: 85% !important;
    border-radius: 8px;

}
.carouselWrapper .mediamodalsectionwrapper> {
    height: auto !important;

}
.medialmodalcomments, .mediamodalviews{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 50px 1fr;
    padding: 20px 20px;
}
.closeBtn{
    height: 40px !important;
}

.carousel .slider-wrapper{
    height: 100%;
}
.carousel-root, .carousel.carousel-slider{
    height: 100%;
    display: flex;
    align-items: center;
}

.mediamodalcommentheader{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

}
.mediamodalcommentheader h2{
    font-size: 1.5rem;

}
.mediamodalcommentsscrollwrapper{
    padding: 0 20px;
}
.mediamodalcommentsscroll{
    max-width: 768px;
}
.mediamodalviewsscroll >div{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.moreBtn .settingsDropdown span{
    top: -5px !important;
}

.mediasinglemainmodal{
    z-index: 10300 !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0,0,0,0.7);
}