.whiteiconwrapper {
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid #E2E2F0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.whiteiconwrapper svg {
  stroke: black;
}

.whiteiconwrapper:hover {
  background-color: rgba(255, 89, 23, 0.08);
  border: 1px solid rgba(255, 89, 23, 0.08);
}

.whiteiconwrapper:hover svg {
  stroke: #FF5917;
}

.whiteiconwrapper .settingsDropdown {
  right: 0 !important;
}

@media (min-width: 1400px) {
  .whiteiconwrapper .settingsDropdown span {
    top: -6px !important;
    right: 20px !important;
    left: unset !important;
  }
}

.orangeicon {
  background-color: #FF5917;
  transition: transform 0.3s ease-in-out;
}

.orangeicon svg {
  stroke: white;
}

.orangeicon:hover {
  transform: scale(1.1);
}

.whiteicon {
  background-color: white;
  border: 1px solid #E2E2F0;
  transition: transform 0.3s ease-in-out;
}

.whiteicon svg {
  stroke: black;
}

.whiteicon:hover {
  transform: scale(1.1);
}

.whiteicon:hover svg path {
  color: #FF5917;
}

.chat_send_iconwrapper {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}



.mainchat {
  width: 100%;
  font-family: 'Inter', sans-serif;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.mainchatlayout {
  width: 100%;
  height: 100%;
}

@media (min-width: 1920px) {
  .mainchat {
    display: flex;
    align-items: center;

  }

  .mainchatlayout {
    max-width: 1920px;
  }
}

.chat_topbar {
  /* height: 104px; */
  height: 6.5rem;
  padding: 1.5rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #E2E2F0;
  background-color: white;
  z-index: 1000;
}

.chat_topbarleft {
  align-self: flex-start;
  display: flex;
  gap: 1.5rem;
}

.chat_topbarleft img {
  height: 3rem;
  object-fit: contain;
}

.chat_topbarleft hr {
  height: 3.5rem;
  /* width: 1px; */
  border: 0.1px solid #E2E2F0;
}

.chat_topbarleft h4 {
  font-weight: 600;
  font-size: 1rem;
  color: #040405;
  align-self: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat_righttopbar {
  display: flex;
  gap: 2.5rem;
}

.chat_main_user {
  display: flex;
  gap: 1rem;
}

.chat_main_user_img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: .5rem;
}

.chat_main_user_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: .5rem;
}

.chat_main_userDetails {
  display: none;
}

@media (min-width: 1000px) {
  .chat_main_userDetails {
    display: flex;
    flex-direction: column;
    gap: 0.1875rem;
  }
}

.chat_main_userDetails h4 {
  font-weight: 600;
  color: #040405;
  line-height: 1.25rem;
  font-size: 0.875rem;

  width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat_main_userDetails span {
  font-weight: 400;
  color: #7D7D8D;
  font-size: 0.875rem;

  width: 10ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 1200px) {
  .chat_main_userDetails span {
    width: 15ch;
  }

  .chat_main_userDetails h4 {
    width: 15ch;
  }
}

.chat_centertopbar {
  align-self: center;
}

/* CHAT SEARCH */
.mainchatinputwrapper {

  width: 22.5rem;
  background: var(--cl_bggrey);
  border-radius: 0.5rem;
  margin: 0 0.625rem;
}

.minichatinputwrapper {
  max-width: 360px;
}

.minichatinputwrapper,
.mainchatinputwrapper {
  /* height: 100%;
    width: 100%; */
  height: 2.5rem;
  background: #F5F5F6;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 0.875rem;
  gap: 10px;
  align-items: center;
  padding: 0 1rem;
}

.minichatinputwrapper input,
.mainchatinputwrapper input {
  border: none;
  background: #F5F5F6;
  border-radius: 0.5rem;

  height: 100%;

}

.minichatinputwrapper svg,
.mainchatinputwrapper svg {
  stroke: #7D7D8D;
}

/*the chat*/
.chatwrapper {
  height: calc(100vh - 6.5rem);
}

.chatcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 89vh;
}

.chat_users_container {
  width: 45%;
  position: relative;
}

.chat_messages_container {
  height: 100%;
  width: 55%;
}

.chat_users_wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;

}

.chat_details_container {
  display: none;
}

@media (min-width:1400px) {
  .chat_users_container {
    width: 25%;
  }

  .chat_messages_container {
    width: 50%;
  }

  .chat_details_container {
    display: flex;
    width: 25%;
  }
}

.chat_users,
.chat_container,
.chat_details {
  /* height: calc(100vh - 106px); */
  height: 100%;
}

.chat_btns_container {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-bottom: 1px solid #E2E2F0;
}

.chat_btns_container button {
  min-width: 9.25rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.25;
  display: flex;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;

}

.chat_btns_container .BtnWhite {
  gap: 0.3rem;
}

.chat_btns_container svg {
  font-size: 1.2rem;
}

.BtnOrange {
  height: 2.5rem;
  border: none;
  outline: none;
  background-color: #FF5917;
  border-radius: 0.5rem;
  padding: 0.625rem 1.125rem;
}

.BtnWhite {
  height: 2.5rem;
  border: 1px solid #E2E2F0;
  outline: none;
  background-color: white;
  border-radius: 8px;
  padding: 0.625rem 1.125rem;
}

.chat_messages_container {
  border-left: 1px solid #E2E2F0;
  border-right: 1px solid #E2E2F0;
}

.chat_user,
.chat_userskeleton {
  height: 5rem;
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  border-bottom: 1px solid #E2E2F0;
  overflow: hidden;
  padding: 1.25rem 1.5rem;
  gap: 1rem;
  transition: background 0.3s ease-in-out;
}

.chat_user:hover {
  background-color: var(--cl_bggrey);
}

.chat_user_menu_active {
  background-color: var(--cl_bggrey);
}

.chat_useractive {
  background-color: #FFF2EC !important;
}

.chat_user_dp {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  position: relative;
}

.user_online_stat {
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: #15CF74;
  bottom: .2rem;
  right: .2rem;

}

.chat_user_selected {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;

}

.chat_user_dp img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 50%;
}

.chat_user_name {
  display: grid;
  grid-template-columns: 1fr 0.8125rem .5rem;
  gap: .5rem;
  align-items: center;
}

.chat_user_name h4 {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  /* color: #040405; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_pinned {
  display: flex;
  align-items: center;
}

.user_pinned svg {
  height: 1rem;
  font-size: 2rem;
}

.user_recentmsg_number {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: var(--cl_orange);
}

.chat_user_recent {
  display: grid;
  grid-template-columns: 1fr 0.8125rem auto;
  gap: 0.5rem;
  align-items: center;
}

.chat_user_recent {
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #7D7D8D;
}

.chat_user_recent span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_mute {
  display: flex;
  align-items: center;

}

.user_mute svg {
  font-size: 1rem;
}


/* chat details*/
/* .chat_details_container{
    padding: 0 1.5rem;
} */
.chat_details_wrapper {
  width: 100%;
  /* padding: 0 1.5rem; */
}

.MuiAccordion-root {
  box-shadow: none !important;
  border-bottom: 1px dashed #E2E2F0 !important;
  margin: 0 1.5rem !important;
}

.Mui-expanded {
  /* margin: 0 !important; */
  /* margin: 0 1.5rem !important; */
}

.MuiAccordionSummary-root {
  min-height: 4.375rem !important;
}

/* chat messages*/
.chat_messages_content {
  width: 100%;
  height: 100%;
  display: grid;
  transition: all 0.3s ease-in-out;
  grid-template-rows: 5rem 1fr auto;
  /* grid-template-rows: auto 1fr auto; */
}

.chat_messages_content_emoji_inactive {
  /* grid-template-rows: 5rem 1fr 4.625rem; */
  /* animation: sizedecrease 1s linear 0s forwards; */
  /* animation: example 5s linear 2s infinite alternate; */
}

.chat_messages_content_emoji_active {
  /* animation: sizeincrease 1s linear 0s forwards; */
  /* grid-template-rows: 5rem 1fr 23.375rem; */
}

/* .chat_user_intro_header_common{
  
} */
.chat_user_intro_header {
  padding: 1.25rem 1.5rem;
  /* display: flex;
    justify-content: space-between; */
  display: grid;
  grid-template-columns: 1fr auto;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #E2E2F0;
}

/* .chat_user_intro_header_expanded{
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 391px;
  } */
.chat_user_intro_header_left {
  display: flex;
  gap: 1rem;
}

.chat_user_intro_header_right {
  display: flex;
  gap: 0.5rem;
}

.chat_user_intro_header_left_img {
  max-width: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;


  max-height: 2.5rem;
  border-radius: 50%;
  position: relative;
}

.chat_user_intro_header_left_img img {
  /* width: 40px;
    height: 40px; */
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.chat_user_intro_header_left_status {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  min-width: 0.5rem;
  min-height: 0.5rem;
  max-width: 0.5rem;
  max-height: 0.5rem;
  background-color: #15CF74;
  border-radius: 50%;
  right: 0;
  bottom: 0;
}

.chatmsguserdetails h3 {
  font-size: 0.875rem;
  font-weight: 600;

}

.chatmsguserdetails small {
  font-size: 0.8125rem;
  /* line-height: 20px; */
  color: #7D7D8D;

}

/*chat send*/
.chat_send {
  /* position: sticky;
    bottom: 0; */
  position: relative;
  background-color: white;
  width: 100%;
  transition: all 0.3s ease-in-out;


  display: flex;
  flex-direction: column;
  /* z-index: 20000 !important; */
  z-index: 1000 !important;
  transition: height 0.3s ease-in-out;
  align-self: flex-end;
}

.chat_sendactive {
  /* height: 374px; */
  height: 420px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  /* animation: sizeincrease 1s linear 0s forwards; */
}

.chat_sendinactive {
  height: 74px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  /* animation: sizedecrease 1s linear 0s forwards; */
}


/* @keyframes sizeincrease {
    from {
      grid-template-rows: 5rem 1fr 4.625rem;
    }
    to {
      height: 74px;
    }
  }
  @keyframes sizedecrease {
    from {
      height: 374px;
    }
    to {
      height: 74px;
    }
  } */
.chat_send_wrapper {
  width: 100%;
  /* height: 100%; */
  padding: 10px 24px 24px 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  /* align-self: flex-end; */
  max-height: 4.625rem;
}

.chat_input {
  height: 40px;
  border: none;
  outline: none;
  background: #F5F5F6;
  border-radius: 8px;
}

.chat_send_icons {
  display: grid;
  grid-template-columns: 1fr 40px;
  gap: 8px;
}

.chat_additional_icons {
  display: flex;
  gap: 8px;
}

.chat_send_iconwrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}



.chat_messages>div {
  display: flex;
  flex-direction: column-reverse;
  grid-gap: 8px;
  gap: 8px;
  padding: 10px 0;
  /* gap: 5px; */
  /* padding: 5px 0; */
}

/* MESSAGE STYLES */

.receivedmsg {
  position: relative;
  width: 100%;
  padding: 0 24px;
  /* max-width: 248px; */
  max-width: 80%;


  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.receivedmsg_msg {
  display: flex;
  gap: 16px;
}

.receivedmsg small {
  margin-left: 40px;
}

.receivedmsg img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  align-self: flex-end;
  justify-content: center;

  /* margin-bottom: 20px; */
  /* margin: 0 16px 0 24px; */
}

.receivedmsg p {
  /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
  /* width: 100%; */
  max-width: 100%;
  display: inline-block;
  padding: 12px 16px;
  /* width: auto; */

  /* height: 84px; */
  background: #FFFFFF;
  border: 1px solid #E2E2F0;
  border-radius: 12px 12px 12px 0px;
}

/* unordered */

/* .mainchat */

.repliermessagecontainer1 {
  position: relative;
  width: 100%;
  padding: 0 24px;
  /* max-width: 248px; */
  max-width: 80%;
  overflow-wrap: break-word;
  align-self: flex-end;
  display: flex;
  align-content: flex-end;
  /* flex-direction: column; */

}

.repliermessagecontainer1 p {
  max-width: 100%;
  display: inline-block;
  padding: 12px 16px;
  background: #FF5917;
  border-radius: 12px 12px 4px 12px;
  color: white;
  margin: 0 0 0 auto;

  /* float: right; */
}

.justsend {
  color: black !important;

}

.chat_img_overlay {
  position: absolute;
  top: 0;
  height: 200px;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 8px;

}

.chat_img_overlaywrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
}

.chat_img_overlay svg {
  color: var(--cl_orange);
}

.recentmessage_userchat {
  display: flex;
  align-items: center;
  gap: 2px;
}

.chatmessageoptions {
  position: absolute;
  box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
  min-width: 200px;
  border-radius: 10px;
  background-color: white;
  /* bottom: 0; */
  z-index: 20000;
}

.sendermessagecontainer1 .chatmessageoptions {
  top: 40px;
  left: 56px;
  /* right: 20px; */
}

.repliermessagecontainer1 .chatmessageoptions {
  top: 40px;
  right: 20px;
}

.chatmessageoptions span {
  position: absolute;
  top: -6px;
  right: 20px;
  /* left: 20px; */
  display: block;
  height: 10px;
  width: 10px;
  background-color: white;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}

.repliermessagecontainer1 .chatmessageoptions span {
  right: 20px;
}

.sendermessagecontainer1 .chatmessageoptions span {
  left: 20px;
}

.chatmessageoptions ul {
  list-style: none;
  padding: 5px;
}

.chatmessageoptions li {
  padding: 15px 20px;
}

.chatmessageoptions li:hover {
  color: var(--cl_orange);
  background-color: var(--cl_light_orange);
  border-radius: 8px;
}


/*chat details */
.chat_details_footerwrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat_details_footer1,
.chat_details_footer2 {
  width: 100%;

  display: flex;
  flex-direction: column;

}

.chat_details_footer1>div,
.chat_details_footer2>div {
  height: 55px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: background 0.3s ease-in-out;
  /* background-color: #F5F5F6 !important; */
}

.chat_details_footer1>div:hover,
.chat_details_footer2>div:hover {
  background-color: var(--cl_light_orange);
}

.chat_details_footer1 svg,
.chat_details_footer2 svg {
  font-size: 1.5rem;
  color: var(--cl_orange);

}

/*send message*/
.sendmessage_container {
  height: 100%;
  width: 100%;
  top: 0;
  /* background-color: red; */
  position: absolute;
  transition: transform 0.5s ease-in-out;
  background-color: white;
}

.sendmessage_container_inactive {
  transform: translateX(-100%);
}

.sendmessage_container_active {
  transform: translateX(0);
}

.sendmessage_content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 6rem 1fr;
}

.sendmessage_search {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.sendmessage_search .mainchatinputwrapper {
  max-width: auto !important;
  width: 100%;
}

.sendmessage_backbtn {
  width: 100%;
  padding: 0 1rem;
}

.sendmessage_backbtn svg {
  font-size: 1.5rem;
}

/* new group */
.addgroup_container {
  height: 100%;
  width: 100%;
  top: 0;
  /* background-color: red; */
  position: absolute;
  transition: transform 0.5s ease-in-out;
  background-color: white;
}

.addgroup_container_inactive {
  transform: translateX(-100%);
}

.addgroup_container_active {
  transform: translateX(0);
}

.addgroup_screenscontainer {
  height: 100%;
  width: 100%;
  /* background-color: blue; */
  position: relative;
  overflow: hidden;
}

.addgroup_screen1 {
  height: 100%;
  width: 100%;
  /* background-color: green; */
  display: grid;
  grid-template-rows: 80px 1fr 80px;
  position: absolute;
  transition: transform 0.5s ease-in-out;
}

.addgroup_screen1_active {
  transform: translateX(0);
}

.addgroup_screen1_inactive {
  transform: translateX(-100%);
}

.addgroup_screen1_header,
.addgroup_screen2_header {
  height: 80px;
  width: 100%;
  /* background-color: cyan; */
}

.addgroup_screen1_header {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10px;
  padding: 0 25px;
}

.addgroup_screen1_header h2,
.addgroup_screen2_header h2 {
  font-size: 1.4rem;
}

.addgroup_screen1_header svg {
  font-size: 1.7rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.addgroup_screen1_header svg:hover {
  transform: scale(1.07);
}

.addgroup_screen1_footer,
.addgroup_screen2_footer {
  height: 80px;
  width: 100%;
  /* background-color: greenyellow; */
}

.addgroup_screen1_content {
  display: grid;
  grid-template-rows: 200px 1fr;
}

.addgroup_memberadd {
  height: 200px;
  width: 100%;
  display: grid;
  grid-template-rows: 120px 50px;
  /* padding-bottom: 10px; */
}

/* .addgroup_addedmembers{

  background-color: rgb(250, 250, 250);
} */
.addgroup_addedmembers>div {
  display: flex;
  flex-direction: column;
  gap: .1rem;
  flex-wrap: wrap;
  padding: .2rem .2rem;
}

/* .new_group_added_mem svg{
  
  
} */
.addgroup_memberadd input {
  height: 40px;
  /* background-color: transparent; */
  width: 100%;
  border: none;
  /* border-bottom: 1px solid var(--cl_orange); */
  align-self: flex-end;
}

.addgroup_memberinput {
  padding-left: 15px;
}

.addgroup_screen1_footer {

  display: flex;
  align-items: center;
  justify-content: center;
}

.addgroup_screen1_footericonwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.addgroup_screen1_footericonwrapper_active {
  background-color: var(--cl_orange) !important;
  color: white;
}

.addgroup_screen1_footer svg {
  transform: rotate(180deg);
  font-size: 1.7rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.addgroup_screen1_footer svg:hover {
  transform: rotate(180deg) scale(1.07);
}

.addgroup_screen2 {
  height: 100%;
  width: 100%;
  /* background-color: orange; */
  /* display: grid;
  grid-template-rows: 80px 1fr 80px; */
  position: absolute;
  transition: transform 0.5s ease-in-out;
}

.addgroup_screen2_active {
  transform: translateX(0);
}

.addgroup_screen2_inactive {
  transform: translateX(100%);
}

.addgroup_screen2_header {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10px;
  padding: 0 25px;
}

.addgroup_screen2_header svg {
  font-size: 1.7rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.addgroup_screen2_header svg:hover {
  transform: scale(1.07);
}

.addgroup_screen2_content {
  display: flex;
  align-items: center;
  justify-content: center;

}

.addgroup_screen2_content .Profile-update-PageDpImg,
.addgroup_screen2_content .profile-update-dp {
  border-radius: 50% !important;
}

.addgroup_groupdetails {
  width: 80%;
  max-width: 500px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.addgroup_groupicon {
  position: relative;
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.addgroup_groupicon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 50%;
}

.addgroup_groupicon_icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.addgroup_detailsinput {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.addgroup_detailsinput input {
  width: 100%;
  border: none;
  /* border-bottom: 1px solid var(--cl_orange); */
  border-radius: 8px !important;
  min-height: 40px;
  outline: none;
  padding-left: 15px;
  background-color: var(--cl_bggrey);
}

.addgroup_final {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
}

.addgroup_final svg {
  transform: rotate(180deg);
  font-size: 1.7rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.addgroup_final svg:hover {
  transform: rotate(180deg) scale(1.07);
}

.addgroup_final_active {
  background-color: var(--cl_orange) !important;
  color: white;
}

.addgroup_final_active svg {
  color: white !important;
}

/*emojimart */
.emoji-mart {
  width: 100% !important;
  /* height: 310px;
  position: absolute;
  transition: bottom 0.3s ease-in-out; */
}

/* .chat_sendactive .emoji-mart{
  bottom: 0;
}
.chat_sendinactive .emoji-mart{
  bottom: -374px;
} */
.emoji-mart-preview {
  display: none;
}

.emoji-mart-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.emoji-mart-scroll::-webkit-scrollbar {
  display: none;
}

/* shared */
.chat_shared_media {
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-rows: 96px 96px ; */
  gap: 8px;
}

.shared_media_tile {
  width: 96px;
  height: 96px;
  background-color: var(--cl_bggrey);
}

/* options */
.MuiMenu-root {
  min-width: 300px;
}

.MuiModal-root {
  z-index: 23000 !important;
}

.MuiMenu-paper {
  min-width: 200px !important;
  /* left: 102px !important; */
  transform: translatex(-50px) !important;
  z-index: 11000 !important;
}

.MuiMenu-list {
  padding: 5px !important;
  font-family: 'Inter' sans-serif;
}

.MuiMenu-list li {
  font-family: 'Inter' sans-serif !important;
}

/* .MuiPaper-root{
  min-width: 300px !important;
} */
.MuiMenuItem-root {
  font-family: 'Inter' sans-serif !important;
}


.groupdetailsmodal {
  /* min-width: 500px;
  width: 40vw; */
  min-height: 50vh;
  /* background-color: red; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.groupinfo_container {
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  /* align-items: center; */
  width: 100%;
}

.closegrpdetmodal {
  margin: 10px 10px 0 auto;
}

.groupinfoimage,
.groupinfoimage img {
  /* width: 150px; */
  /* height: 150px; */
  /* border-radius: 50%; */
  width: 100%;
  /* height:60%; */
  min-width: 300px;
  max-height: 300px;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.groupinfoimage_overlay {
  /* height: 30%;
  width: 100%;
  position: absolute;
  bottom: -30%;
  right: 0;
  border-radius: 50% 50% 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.411);
  transition: all .3s ease-in-out; */
  position: absolute;
  top: 8px;
  right: 16px;

}


/* .groupinfoimage_creator:hover .groupinfoimage_overlay {
  bottom: 0;
} */

.groupinfoimage img {
  object-fit: cover;
  object-position: center;
}

.profile_name_chatmessage {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-left: 16px;
  padding-top: 5px;
  height: 40px;
  /* left: 16px; */
  background: rgba(0, 0, 0, 0.3);
  color: white;
  /* background-color: #ee7aa1; */

}

.profile_popup_body {
  /* text-align: 'center'; */
  display: 'flex';
  flex-direction: 'column';
  gap: '.7rem';
  color: 'var(--cl_textgrey)';
  margin-top: 2;
  margin-left: 5;
  align-content: center;
  padding-left: 5;
  min-height: 7rem;
  text-Overflow: 'ellipsis';
  position: relative;
  /* top: 8px; */

}

.profile_popup_body div {
  position: absolute;
  top: 12px;
  left: 16px;
}

.profile_popup_body span {
  position: absolute;
  top: 35px;
  left: 16px;
}


.datetab_chat {
  /* font-size: 16px; */
}

.datetab_chat span {
  /* height: 100px; */
  height: 30px;
  display: flex;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 13px;
  align-items: center;
  /* justify-content: center; */


}