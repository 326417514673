.chat_userdetails {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.chat_user_details_item {
    min-height: 2.5rem;

    display: flex;
    padding: 0.25rem 0;
    gap: 1rem;
}

.Chat_user_detail_info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.Chat_user_detail_info small {
    color: #7D7D8D;
    font-size: 0.8125rem
}

.Chat_user_detail_info span {
    font-size: 0.875rem;
    color: black;
}

.MuiAccordionDetails-root {
    padding: none !important;
}

#panel1a-header123 .MuiAccordion-root {
    box-shadow: none !important;
    border-bottom: 0px dashed #E2E2F0 !important;
    /* margin: 0 1.5rem !important; */
    margin: 0 !important;
}

.MuiAccordionSummary-content {
    margin: 0 !important;
}

.chat_user_dtitem {
    display: flex;
    /* gap: 1rem; */
    width: 100%;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
}

.chat_user_dtitem h6 {
    padding-left: 2px;
    margin-left: 7px;
}

.chat_user_dtitem h6 {
    font-size: 0.875rem;
}

.chat_details_container .MuiAccordionSummary-root {
    padding: 0 !important;
}

.MuiAccordion-root:before {
    background-color: transparent !important;
}

.MuiAccordion-root .MuiAccordion-root {
    border: none !important;
    margin: 0 !important;
}

.chatuser_members {
    min-height: 350px;
}

.chatuser_doc,
.chat_shared_media {
    max-height: 300px;
    height: auto;
    min-height: 200px;
    width: 100%;
}

.chatuser_doc>div {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.chat_doc_item {
    min-height: 2.5rem;
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    gap: 1rem;
    align-items: center;
}

.chat_doc_item h6 {
    font-size: 0.8715rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.addchatmember,
.chatremove_member {
    height: 2.5rem;
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    border-radius: .5rem;
    gap: .5rem;
    background-color: var(--cl_light_orange);

    border: .5px solid var(--cl_bggrey);
    transition: all 0.3s ease-in-out;
}

.chatremove_member {
    margin-top: .5rem;
    margin: .5rem;
    padding: .7rem;

}

.addchatmember:hover,
.chatremove_member:hover {
    background-color: var(--cl_orange);
}

.addchatmember svg {
    font-size: 1rem;
}