.toast__container{
    /* width: 30vw; */
    /* border:  1px solid rgb(209, 209, 209); */
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    gap: 20px;
    min-width:200px ;
}

.toast__container__icon{

    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
}
.toast__container__text{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
}

.toast__msg__wrapper{
    border-radius : 15px;
    display: flex;
    width: 500px;
    height: 80px;
    padding : .8rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color:#ffffff;
    align-items: center;
    justify-content: space-around
}



.toast__msg__dp{
    /* height: 40px;
    width: 40px;
    padding:none; */
  }
 
  .toast__msg__dp img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
  }

  .toast__msg__text{
      display:flex;
      flex-direction: column;
      /* justify-content: space-around; */
      width: 100%;
      height: 100%;
      padding : 10px;
      /* gap: 10px; */

  }