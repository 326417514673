.eventileLong{
    height: 380px;
    display: grid;
    grid-template-columns:1fr;
    grid-template-rows: 210px 1fr;
    border-radius: 12px;
    border: 1px solid rgba(125, 125, 141, 0.16);
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
}
.eventileLong:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.TileLeftSection{
    position: relative;
}
.eventLocDiv{
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    background-color: white;
    font-size: var(--font-size-10);
    height: 24px;
    border-radius: 24px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 5px;
    min-width: auto;
    transition: box-shadow 0.3s ease-in-out;
    font-weight: 600;
}
.eventLocDiv:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    min-width: 110px;
}

.eventLocDiv:hover .thepmarquee{
    position: absolute;
    /* min-width: 110px; */
    width: 100%;
    /* height: 100%; */
    margin: 0;
    line-height: 50px;
    text-align: center;
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: scroll-left 2s linear infinite;
    -webkit-animation: scroll-left 2s linear infinite;
    animation: scroll-left 4s linear infinite;
}

@-moz-keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
    }
}

@-webkit-keyframes scroll-left {
    0% {
        -webkit-transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}


.TileLeftSection img{
    height: 210px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.TileRightSection{
    padding: 29px 32px 0 36px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.Right-Section-Top{  
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.Right-Section-Top .user-details {
    display: flex;
    font-size: var(--font-size-10);
    align-items: center;
    gap: 4px;
    /* justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    font-size: .55rem; */
    color: rgb(143, 142, 142);
}
/* .user-details{
    width: 100%;
} */
.user-details span{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.user-dp{
    height: 16px;
    width: 16px;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 50%;
    margin-right: 4px;
}
.user-details .dot{
    
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: rgb(143, 142, 142);

}

.user{
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}
.user:hover{
    color: black;
}
.Right-Section-Top svg{
   transition: transform 0.3s ease-in-out;
   cursor: pointer;
}
.Right-Section-Top svg:hover{
    transform: scale(1.12);
}
.event-details{
    font-size: var(--font-size-20);
    font-weight:600;
    /* text-transform: capitalize; */
}

.event-rating{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: var(--font-size-12);
    font-weight:600;
    padding-top: 5px;
}
.Right-Section-bottom {
    display: flex;
    width: 100%;
    margin-top: 28px;
}
.eventparticipantimages{
    margin-left: auto;
    display: flex;
}
.eventparticipantimageswrapper{
    height: 24px;
    width: 24px;
    overflow: hidden;
    border: 1px solid white;
    border-radius: 50%;
}
.eventparticipantimageswrapper img{
    height: 24px;
    width: 24px;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.eventparticipantimageswrapper:nth-child(1){
    z-index: 5;
}
.eventparticipantimageswrapper:nth-child(2){
    transform: translateX(-10px);
    z-index: 3;
}
.eventparticipantimageswrapper:nth-child(3){
    transform: translateX(-20px);
    z-index: 2;
}

@media (min-width: 900px){
    .eventileLong{
        height: 210px;
        display: grid;
        grid-template-columns: 336px 1fr;

    }
    .TileLeftSection img{
        height: 210px;
        width: 336px;

    }
}