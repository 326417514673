.profile{
    display: flex;
    flex-direction: column;
    gap: 48px;
}
.coverPhoto{
    height: 380px;
    width: 100%;
    border-radius: 12px;
    /* overflow: hidden; */
    position: relative;
    background-color: var(--cl_bggrey);
}
.coverPhotoimg{
    height: 380px;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 12px;
}
.coverPhoto svg{
    position: absolute;
    bottom: -10px;
    right: -10px;
}
.profileDetails{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 16px;
    /* grid-template-columns: 1fr 312px; */
}
.profileDetails .row1{
    width: 312px;
    /* height: 268px; */
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 30px;
    /* background-color: red; */
}
.profileDetails .row1 h3{
    font-weight: 600;
    font-size: var(--font-size-20);
}
.contactDetails{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.contactDetails hr{
    border: none;
    height: 1px;
    background-color: rgba(226, 226, 240, 1);
}
.contact{
    display: flex;
    align-items: center;
    gap: 12px;
}
.contact p,.contact a{
    text-decoration: none;
    font-size: var(--font-size-13);
}
.profileDetails .row2{
    width: 312px;
    height: 170px;
    /* height: 268px; */
    border: 1px solid rgba(125, 125, 141, 0.16);
    border-radius: 12px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.profileDetails .col1{
    display: flex;
    flex-direction: column;
    gap: 37px;
}
.userDetails{
    /* height: 300px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 33px;
}
.user{
    display: flex;
    gap: 30px;
}
.name{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.name h1{
    font-size: 2rem;
    font-weight: 600;
    color: rgba(4, 4, 5, 1);
}
.name span{
    color: rgba(125, 125, 141, 1);
    /* font-size: 14px; */
    font-size: var(--font-size-13);
    font-weight: 500;
}
.profileDp{
    height: 80px;
    width: 80px;
    position: relative;
    border: 1px solid white;
    border-radius: 16px;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    box-shadow: 0 0 10px hsla(0,0%,73.3%,.5);
    /* box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.profileDp img{
    /* height: 80px;
    width: 80px; */
    height: 72px;
    width: 72px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 16px;
}
.profileDp svg{
    position: absolute;
    bottom: -8px;
    right: -8px;
}
.profileDetails .bio{
    font-weight: 400;
    font-size: var(--font-size-13);
    line-height: 19.5px;
    white-space: pre-wrap;
    word-break: break-word;
}
.profileDetails .col2{
    display: flex;
    /* flex-direction: column; */
    gap: 16px;
}
@media (min-width: 1000px){
    .profileDetails{
        grid-template-columns: 1fr 312px;
    }
    .profileDetails .col2{
        /* flex-direction: column; */
        flex-direction: column-reverse;
    }
}
@media (min-width: 1400px){
    .profileDetails{
        grid-template-columns: 1fr 312px;
    }
}
/* @media (min-width:  1800px){
    .profileDetails{
        grid-template-columns: 1fr 640px;
    }
    .profileDetails .col2{
        flex-direction: row;
    }
} */
@media (min-width:  1920px){
    .profileDetails{
        grid-template-columns: 1fr 640px;
    }
    .profileDetails .col2{
        flex-direction: row;
    }
}
.stories{
    display: flex;
    gap: 16px;
    overflow: auto;
    width: 696px;
}
.storyBox{
    height: 200px;
    width: 160px;
    min-width: 160px;
    background-color: var(--cl_bg-grey);
    border-radius: 12px;
}

@media (min-width:  1000px){
    .stories{
        width: 520px;
    }
}
@media (min-width:  1150px){
    .stories{
        width: 696px;
    }
}
@media (min-width:  1800px){

}
.btnContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 40px 40px; */
    /* grid-template-areas: 
    "header header"
    "footer"; */
    gap: 6px;
}
.popularity {
    display: flex;
    justify-content: space-around;
}
.popularity .col{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}
.popularity .col h3{
    font-size: 1rem;
    font-weight: 600;
}
.pop{
    display: flex;
    gap: 5px;
    padding-bottom: 24px;
}
.pop span{
    color: rgba(125, 125, 141, 1);
    font-size: 11px;
}
.navContainer{
    display: flex;
    gap: 48px;
    border-bottom: 1px solid rgba(226, 226, 240, 1);
}
.subnavLink{
    height: 40px;
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    transition: color 0.3s ease-in-out,border 0.3s ease-in-out,transform 0.3s ease-in-out;
}
.subnavLinkActive{
    color: var(--cl_orange);
    border-bottom: 1px solid var(--cl_orange);
    transition: transform 0.3s ease-in-out;
}
.subnavLink:hover,.subnavLinkActive:hover{
    transform: scale(1.05);
}
/* .contentContainer{
    height: 100%;
    width: 100%;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
} */
/* .eventGrid, .mediaGrid{
    width: inherit;
    height: 100%;
    background-color: var(--cl_bggrey);
    min-height: 350px;
    margin-top: 48px;
}
.eventGrid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(2,400px);
    gap: 16px;
}
.eventtile{
    background-color: blue;
} */
.eventGrid{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.mediaGrid{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding: 30px 0;
}
@media (min-width:1400px){
    .mediaGrid{
        grid-template-columns: repeat(4,320px);
    }
}


.shadowBtns{
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 106px;
    left: 0;
    z-index: 1200;
    align-items: center;
    background-color:rgb(0,0,0,0.2) ;
    width: 100vw;
    height: 60px;
    padding: 10px;
    gap: 10px;
}


.privateProfile{
    min-height: 500px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--cl_lightgrey);
}
.profile .menuContainer{
    padding-top: 40px;
    /* padding-bottom: 1px; */
    position: sticky;
    position: -webkit-sticky;
    top: 106px;
    background-color: white;
    z-index: 100;
    /* box-shadow: rgb(0 0 0 / 20%) 0px 60px 40px -7px; */
    box-shadow: rgb(0 0 0 / 4%) 0px 25px 15px -20px;
}
.profile .eventGrid{
    margin-top: 0 !important;
}