.landing__page__container {
	display: flex;
	flex-direction: column;
	width: 90vw;
	max-width: 1440px;
	padding: 2rem;
	font-family: 'Inter', sans-serif;
	font-size: .80rem;
	position: relative;
	overflow: hidden;
	background-position: right bottom ,-6rem -6rem;
	background-repeat: no-repeat;
	background-size: 60% 40px;
	background-origin:  border-box; 

	/* border: 1px solid blue; */
}

.landing__page__container main {
	padding: 2rem;
}

.landing__page__container h1 {
	font-weight: 700;
	font-size: 4.5em;
	/* font-size: 4.5vw; */
	/* letter-spacing: .01em; */
	/* text-align: center; */
}

.landing__page__container h2 {
	font-weight: 700;
	font-size: 3.5em;
	/* font-size: 2.5vw; */
}

.landing__page__container h3 {
	font-weight: 700;
	font-size: 2.5em;
	/* font-size: 2vw; */
}

.landing__page__container h4 {
	font-weight: 400;
	font-size: 1.5em;
	/* font-size: 1.25vw; */

	line-height: 2.5rem;
	letter-spacing: 0em;

	/* font-size : 1vw */
}

.landing__page__container h5 {
	font-weight: 400;
	font-size: 1.25em;
	/* font-size: 1vw; */

	line-height: 2.5rem;
	letter-spacing: 0em;
}

.landing__page__container small {
	font-size: 1rem;

	line-height: 2.5rem;
	letter-spacing: 0.1em;
	font-weight: 600;
	color: #ff5917;
}

.landing__page__container button {
	font-size: 1rem;
	font-weight: 500;
	background-color: #ff5917;
	width: 100%;
	border: none;
	border-radius: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	padding: 1.5rem;
	letter-spacing: 0.05em;
}

.grey {
	color: #7d7d8d;
}

.border__class{
	 border-left: 1px solid var(--cl_light_grey);
	 border-right: 1px solid var(--cl_light_grey);
}

/*  &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */

.landing__logoMain {
	position: relative;
	height: 60px;
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	min-height: 60px;
	max-height: 60px;
	cursor: pointer;
	object-fit: contain;
	/* -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; */
}

.landing__logoMain img {
	/* width: 100%; */
	/* height: 100%; */
	height: 60px;
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	min-height: 60px;
	max-height: 60px;
}

.landing__page__sec__1 {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	/* border: 1px solid red; */
	align-items: center;
	/* max-height: 70vh; */
	/* height:100%; */
}

/* .landing__page__sec__1 h1 {
	font-weight: 700;
	font-size: 4.5em;
	font-size: 4.5vw;
}

.landing__page__sec__1 h4 {
	font-weight: 400;
	font-size: 1.5em;
	font-size: 1.25vw;

	line-height: 2.5rem;
	letter-spacing: 0em;
} */

.landing__page__sec__1 div {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/* border: 1px solid blue; */
	height: 100%;
	/* width: 50%; */
	gap: 4em;
}

/* .landing__page__sec__1 .sec__1__part__1{

} */

.sec__1__part__1{
	width:40%
}
.sec__1__part__2{
	width:60%
}

.relativeposition {
	position: relative;
	/* border: 1px solid red; */
	/* width: 100%; */
}

.absolutepostion {
	position: absolute;
	bottom: -25px;
	left: 0;
	z-index: -1;
	/* width: 100%;
    height: 100%;
    object-fit: contain; */
}

.landing__page__sec__1 img {
	display: flex;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.landing__page__sec__2 {
	display: flex;
	justify-content: space-around;
	/* border: 1px solid blue;   */
	width: 100%;
	flex-wrap: wrap;
}

.landing__page__sec__2 div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 2rem 0px;
	flex:1;
}

.landing__page__sec__3 {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 3rem 0px;
	/* width: 100%; */
}

.landing__page__sec__3 > * {
	/* border: 1px solid red; */
	text-align: center;
	padding: 1rem;
}

.landing__page__feature__wrapper {
	display: flex;
	width: 100%;
	padding: 2rem 0px;
}

.feature__wrapper__left {
	display: flex;
	flex-direction: column;
	width: 50%;
	gap: 2.5rem;
	/* border: 1px solid red; */
	justify-content: center;
	/* flex: 1; */
}

.feature__wrapper__right {
	display: flex;
	justify-content: center;
	/* border: 1px solid red; */
	width: 50%;
	padding: 2rem;
}
.feature__wrapper__right img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.book_icon_style {
	position: relative;
	height: 48px;
	width: 48px;
	min-width: 48px;
	max-width: 48px;
	min-height: 48px;
	max-height: 48px;
	object-fit: contain;
	/* -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; */
}

.book_icon_style img {
	/* width: 100%; */
	/* height: 100%; */
	height: 48px;
	width: 48px;
	min-width: 48px;
	max-width: 48px;
	min-height: 48px;
	max-height: 48px;
}

.landing__page__feature__wrapper p {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	/* align-items: center; */
	gap: 1.5rem;
}

.landing__page__feature__wrapper span {
	display: flex;
	/* justify-content: center; */
	align-items: center;
	gap: 1.5rem;
}

.contain_image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	padding-left: 3.5rem;
	/* border: 1px solid red; */
}

.plans__wrapper {
	/* border: 1px solid red; */
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 5rem 0px;
}

.plans__wrapper p {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	/* align-items: center; */
	/* gap: 1.5rem; */
}

.plans__wrapper span {
	display: flex;
	/* justify-content: center; */
	align-items: center;
	gap: 1.2rem;
}

.plan__container__1 {
	max-width: 32.5%;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 1rem;
	/* align-items:center; */
}

.plan__container__1 {
	background: rgb(0, 0, 0);
	background: linear-gradient(125deg, rgba(0, 0, 0, 0) 60%, rgba(155, 232, 244, 0.989233193277311) 100%);
	border: 1px solid var(--cl_border_grey);
	border-radius: 30px;
}

.pink__bg {
	background: linear-gradient(125deg, rgba(0, 0, 0, 0) 60%, #f7c5ec 100%);
}

.plan__container__2 {
	max-width: 32.5%;
	/* padding: 0px 2rem 2rem 2rem; */
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 1rem;
	color: white;
	/* align-items:center; */
}

.inside__2 {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 1rem;
	color: white;
}

.plan__container__2 {
	background: rgb(255, 89, 23);
	background: linear-gradient(to bottom, rgba(255, 89, 23, 1) 40%, rgba(255, 178, 23, 1) 100%);
	/* border: 1px solid blue; */
	border-radius: 30px;
	overflow: hidden;
}

.exploring__events__wrapper {
	/* border: 1px solid red; */
	border-radius: 40px;
	overflow: hidden;
	display: flex;
	/* align-items: flex-end; */
	padding: 6rem 3rem 0rem 3rem;
	flex-wrap: wrap-reverse;

	/* min-height: 400px; */
}

.exploring__events__sec__1 {
	width: 35%;
	max-height: 430px;
	min-width: 350px;
	border: 1px solid red;

	/* height: 100%; */
}

.exploring__events__scrn_1 {
	/* padding: 3rem 4rem 0rem 3rem; */
	/* border: 1px solid  green; */
	width: 100%;
	height: 100%;
}

.exploring__events__scrn_1 img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.exploring__events__sec__2 {
	width: 65%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	/* border: 1px solid blue; */
	text-align: left;
	min-width: 350px;

}

.landing__footer__wrapper {
	display: flex;
	padding : 1rem 0px;
	width: 100%;
	flex-wrap: wrap;
	justify-content:space-between;

}
.landing__footer__sec__1 {
	display: flex;
	flex-direction: column;
	
	/* justify-content: space-around; */
	gap: .25rem;
	/* width: 100%; */
	min-width: 400px;
	padding-top : .5rem;
	/* border: 1px solid red; */
	
	/* flex-grow: 1; */

}
.landing__footer__sec__2 {

	display: flex;
	justify-content:space-between;
	align-items: flex-start;
	width: 50%;
	/* border: 1px solid blue; */

	min-width: 400px;
	/* flex-grow: 1; */
	/* flex-wrap: wrap; */


	
}

.footer__content{
	display: flex;
	flex-direction: column;
	gap: .25rem;
	min-width: 100px;
	/* border: 1px solid red; */
}

.footer_gradient{
	
	position:absolute;
	/* right:-6rem;
	bottom:-.5rem; */
	width: 45%;
	/* height: 50vh; */
	z-index: -1;

}

.footer_gradient img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.white_icons_wrapper {
	max-width: 180px;
	width: 100%;
	
}


.white_icons_wrapper img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

/*sgr*/

.sec__1__part__2{
	display: block !important;
}

.sec__1__part__21{
	display: none !important;
}
@media (max-width:1280px){

	.landing__page__container {
	
		font-size: 0.70rem;
		
	
		/* border: 1px solid blue; */
	}

	.sec__1__part__2{
		display: none !important;
	}
	
	.sec__1__part__21{
		display: block !important;
	}

	.landing__page__container main {
		padding: 1rem;
	}

	.landing__page__container h1 {
		font-weight: 700;
		font-size: 3.5em;
	}

	.landing__page__sec__1 div {
		width: auto;
	}

	.landing__page__sec__2{
		flex-direction: column;
	}
	.landing__page__feature__wrapper{
		flex-direction: column;
	}
	.feature__wrapper__left, .feature__wrapper__right{
		width: auto;
	}
	.landing__page__feature__wrapperMobView{
		flex-direction:column-reverse;
	}
	.plans__wrapper{
		flex-direction:column;
	}
	.plan__container__1, .plan__container__2{
		max-width: none;
		margin-bottom: 5%;
	}
	.landing__page__container {
	
		width: 100vw;
		max-width: 100vw;
		
		
	}


	.landing__page__sec__3{
		padding :.5rem 0px
	}
	
.landing__page__sec__3 > * {
	/* border: 1px solid red; */
	text-align: center;
	padding: .25rem;
}
.landing__page__container{
/* width: 100%; */

width: 100vw;
max-width: 100vw;
padding: .5rem;

background-size: 100% 60vh;



}

.landing__page__container h1{
	/* width: 100%; */
	
font-size:3.1rem

	
	
	}
.grey{
	/* width: 100%; */
	max-width:100vw !important;
}

.feature__wrapper__right {
	display: flex;
	justify-content: center;
	/* border: 1px solid red; */
	width: 100%;
	padding: 2rem .25rem;
}


.border__class{
	border-top: 1px solid var(--cl_light_grey);
	border-bottom: 1px solid var(--cl_light_grey);
		border-left: none;
		border-right: none;
}


.landing__logoMain {
	position: relative;
	height: 60px;
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	min-height: 60px;
	max-height: 60px;
	cursor: pointer;
	object-fit: contain;
	/* -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; */
}

.landing__logoMain img {
	width: 100%;
	height: 100%;
	/* height: 40px;
	width: 70px;
	min-width: 70px;
	max-width: 70px;
	min-height: 40px;
	max-height: 40px; */
}
.landing__footer__sec__2 {

	display: flex;
	justify-content:flex-start;
	align-items: flex-start;
	width: 100%;
	/* min-width: 400px; */
	flex-grow: 1;
	flex-wrap: wrap;
	


	
}
.landing__footer__wrapper {
	display: flex;
	padding : 6rem 1rem;
	width: 100%;
	flex-wrap: wrap;

}


.landing__footer__sec__1 {
	display: flex;
	flex-direction: column;
	/* justify-content: space-around; */
	gap: 1.25rem;
	width: 100%;
	min-width: 400px;
	/* border: 1px solid red; */
	flex-grow: 1;

}
.landing__footer__sec__2 {

	display: flex;
	/* justify-content:space-around; */
	align-items: flex-start;
	width: 100%;
	/* border: 1px solid blue; */

	min-width: 200px;
	flex-grow: 1;
	flex-wrap: wrap;


	
}

.exploring__events__wrapper {
	/* border: 1px solid red; */
	border-radius: 40px;
	overflow: none;
	display: flex;
	/* align-items: flex-end; */
	padding: 4rem 3rem 0rem 3rem;
	justify-content:center
	/* flex-wrap: wrap; */

	/* min-height: 400px; */
}

.store__logo{
	max-width: 180px;
	height: 45px
}

.store_logo_wrapper{
	align-items: center;
	justify-content: center;
	display:flex;
	padding :1rem
}

.plans__wrapper {
	/* border: 1px solid red; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5rem 0px;
}
.contain_image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	padding-left: 0px;
	/* border: 1px solid red; */
}

.landing__page__container h4 {
	font-weight: 400;
	font-size: 1.5em;
	/* font-size: 1.25vw; */

	line-height: 2rem;
	letter-spacing: 0em;

	/* font-size : 1vw */
}

.landing__page__container h5 {
	font-weight: 400;
	font-size: 1.25em;
	/* font-size: 1vw; */

	line-height: 1.5rem;
	letter-spacing: 0em;
}

.landing__page__sec__2 div {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 1rem 0px;
	flex:1;
	
}

.footer__content{
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-width: 300px;
	/* border: 1px solid red; */
}


}