.resetlayout{
    width: 100%;
    padding: 130px 40px 40px 40px;
    /* min-height: calc(100vh - 350px); */
    display: flex;
    justify-content: center;
}
.resetcontainer{
    width: 100%;
    max-width: 688px;
}
@media (min-width: 1200px) {
    .resetlayout {
      padding: 130px 12% 50px 12%;
    }
  }
.resetcontainer input{
    width: 688px;
    height: 40px;
    border-radius: 8px;
    padding-left: 15px;
    border: none;
    outline: none;
}  
.passwordcomponent div{
    width: inherit;

}
.resetcontainer .signupLabelDiv{
    margin-bottom: 10px;
}
.resetcontainer .signupLabelDiv label{
    font-weight: 500;
    font-size: .9rem;
}
.resetcontainer .input-box p{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
/* .resetcontainer .input-box p small{
    color: red;
} */