.react-horizontal-scrolling-menu--wrapper {
  display: flex;
  width: 696px;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
  

.react-horizontal-scrolling-menu--wrapper{
  position: relative;
    display: flex;
    /* gap: 16px;
    overflow: auto; */
    width: 696px;
    overflow: auto;
    scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */


}
.storycontaineroverlay{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.425);

}
.react-horizontal-scrolling-menu--wrapper::-webkit-scrollbar {
  display: none;
}
@media (min-width:  1000px){
  .react-horizontal-scrolling-menu--wrapper{
      width: 520px;
  }
}
@media (min-width:  1150px){
  .react-horizontal-scrolling-menu--wrapper{
      width: 696px;
  }
}