
.container1 {
    height: 100vh;
    width: 100vw;
    min-width: 80vw;
    max-height: 100vh;
  
    /* border: 1px solid black; */
  }
  
  .container-cropper {
    height: 90%;
    padding: 10px;
  }
  
  .cropper {
    height: 90%;
    position: relative;
  }
  
  .slider1 {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 60%;
  }
  
  .container-buttons {
    border: 1px solid #f5f5f5;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }












  .crop-container {
    position: relative;
/* 
    position: 'relative',
    flex: 1,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', */
    flex : 1;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }


  
  .imageClosed {
    display:flex;
    justify-content:center;
    align-items:center;
    /* min-height: 50px;
    min-width: 50px; */
    padding: 10px;
    border-radius: 50%;
    /* overflow: hidden; */
    /* color: rgb(32, 31, 31); */
    background-color:rgb(236, 230, 230);
    transition: .2s ease-in-out;
    color: rgb(48, 47, 47);

    /* border: 1px solid black */
  
  }
  
 .imageClosed :hover {
    transition: .2s ease-in-out;
    font-size: 22px;
    color: rgb(0, 0, 0);

  } 


  div:focus, span:focus{
    outline: none;
}

/* 
.crop-container{
  position: relative;

  overflow: hidden;
}


.cropper-layout{
  border: 1px solid green;
  width:100%;
}



.cropper-croparea-layout{
  border: 1px solid rgb(216, 241, 74);
  width: 1392px;
  height: 380px;

}
   */

   .profile-update-dp{
   /* position: relative; */
   width: 300px;
   height: 300px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 15px;
   border: 4px solid white;
   box-shadow: 0px 0px 10px rgba(187, 187, 187, 0.5);
  }



  .Profile-update-PageDpImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
  }