
.ticketportal__main__layout{
  
    padding: 20px 40px;
    /* border: 1px solid red; */
    display: flex;
    width: 100%;
    
}

.ticketportal__nav__layout{
    /* border: 1px solid red; */
    min-width: 150px;
    width: 100%;
    max-width: 150px;
    height: 100%;
    min-height: calc(100vh - 150px);

    
    position: relative;
}

.ticketportal__component__layout{

    width: 100%;
    /* border: 1px solid blue; */
    height: 100%;
    min-height: calc(100vh - 150px);

}

.nav__route__container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background-color: bisque; */
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--cl_light_grey);

}

.ticketportal__component{
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.tp__componnet__topbar{
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 20px 0px;
    align-items: center;
    border-bottom: 1px solid var(--cl_light_grey);
}

.tp__componnet__wrapper{
    display: flex;
    flex-direction: column;
}

.nav__route__header{
    padding: 0px 0px 5px 0px;
    
    }




.nav__route__wrapper{
    display: flex;
    flex-direction: column;

}

.nav__routes__links {
    display: block;
    border-left: 2px solid var(--cl_border_grey);
    transition: border 0.3s linear;
    text-decoration: none;
	font-size: 1rem;
    font-weight: 600;
    color: rgb(56, 54, 54);
    padding: 10px;
}

.nav__active {
    display: block;

    border-left: 2px solid var(--cl_orange);
    transition: border 0.3s linear;
    text-decoration: none;
	font-size: 1rem;
    font-weight: 600;
    color: var(--cl_orange);
}

.ticketportal__component__container{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 0px 25px;
    /* border: 1px solid green; */
    min-height: calc(100vh - 150px);

}

.ticketportal__component__wrapper{
    display: flex;
    width: 100%;
    min-height: 100%;
padding: 0px 0px 5px 20px;

    /* border: 1px solid red; */
}



.tp__component__top__section { 
    display: flex;
    gap: 20px;
    width: 100%;
    padding: 10px 0px;
    /* border: 1px solid red; */
    flex-wrap: wrap;
}


.tp__component__nav__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: var(--cl_light_grey);
    font-weight: 600;
    font-size: .95rem;
    padding: 8px 8px;
    color: var(--cl_dark_grey);
    /* color: blacj; */
    border-radius: 5px;
    box-shadow: 0px 0px 32px 0px #8898AA26;
    min-width: 140px;
    height:35px;
    transition: background-color  , color .3s ease-in-out;

    
}

.tp__component__sub__section {
    display: flex;
    /* justify-content:center; */
    gap: 15px;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 100%;
    border-radius: 5px;
    height: calc(100vh - 150px);
	min-height: 400px;


    /* padding: 10px 0px; */
}






/* .setting_nav_item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--cl_dark_grey);
    font-weight: 600;
    font-size: 1.1rem;
    padding: 8px 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 32px 0px #8898AA26;
    width: 200px;
    
} */



.nav__active2 {
    background-color: var(--cl_orange);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 5px;

    transition: background-color , color .3s ease-in-out;


}
