.rsvp_container{
    padding:20px;
    display :flex;
    flex-direction: column;
    border-radius: 8px;
}

.rsvp_header{
    padding: 10px 0px;
    
}
.rsvp_header *{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
}
.rsvp_header img{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    width: 60px;
    height: 60px;
}

.rsvp_actions{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
    column-gap: 1em;

}

