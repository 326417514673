.events {
  display: grid;
  /* grid-template-columns: 1fr 60px; */
  grid-template-columns: 1fr 50px;
  gap: 16px;
  height: auto;
}

.eventsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.calendar {
  /* height: calc(100vh - 152px); */
  /* margin-bottom: 100px; */
  width: 50px;
  position: relative;
}

.calendarWrapper {
  width: 336px;
  position: sticky;
  position: -webkit-sticky;
  top: 152px;
  border-radius: 12px;
  transition: height 0.3s ease-in-out;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  z-index: 100;
}

@media (min-width: 1500px) {
  .events {
    grid-template-columns: 1fr 336px;
  }

  .calendar {

    min-width: 336px;
  }

  .calendarWrapper {
    box-shadow: none;
  }
}




/* HostingEvents */
.eventTileList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 32px 0;
}

.attendingEvents,
.hostingEvents {
  padding-bottom: 64px;
}

.loadmore {
  display: flex;
  justify-content: center;
}



/* calendar */
.reactCalendare {
  position: fixed;
  right: 40px;
  /* top:115px;
    left: 40px;
    right: 40px; */
  display: flex;
  z-index: 800;

  /* border: 1px solid blue; */
  height: calc(100vh - 180px);
}

.calendaricon {
  /* margin: auto 0; */
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  /* transform: translateY(20px);
    margin-left: auto; */
  z-index: 800;
}

.calendaricon:hover {
  transform: scale(1.1);
  background-color: var(--cl_orange);
}

.calendaricon:hover circle {
  fill: var(--cl_orange);
  opacity: 1;
}

.calendaricon:hover path {
  stroke: white;
}

.calendarcontainer {
  transition: translate 0.3s ease-in-out, scale 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.visible {
  transform: scale(1) translate(-336px, 20px);
  opacity: 1;
  visibility: visible;
}

.invisible {
  opacity: 0;
  transform: scale(0) translate(80vw, -225px);
  overflow: hidden;
  visibility: hidden;
}

.calendaractive {
  background-color: var(--cl_orange);
}

.calendaractive path {
  stroke: white;
}

/* .invisible .reactCalendare{
    height: 0;
  } */
/* @media only screen and (min-width: 1200px) {
    .EventSearchResultLayout{
      padding: 150px 12% 50px 12%;
    }
  } */
@media only screen and (min-width: 1500px) {
  .calendaricon {
    display: none;
  }

  .visible {
    transform: translate(0px, 0px);
  }

  .invisible {
    transform: scale(1) translate(0px, 0px);
    opacity: 1;
    visibility: visible;
  }
}

.hostingEvents .error {
  min-height: 400px !important;
}

.attendingEvents .error {
  min-height: 400px !important;
}

.highlight {
  /* background-color: var(--cl_orange); */
  font-weight: bold;

}