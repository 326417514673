.css-1jm66jt-MuiClockPicker-root {
	border: 1px solid var(--cl_light_grey) !important;
	border-left: 1px solid var(--cl_light_grey) !important;
	border-right: 1px solid var(--cl_light_grey) !important;
}
.css-hlj6pa-MuiDialogActions-root {
	border-bottom: 1px solid var(--cl_light_grey) !important;
	border-left: 1px solid var(--cl_light_grey) !important;
	border-right: 1px solid var(--cl_light_grey) !important;
    justify-content: space-between !important;
	background-color: white;
}

.css-88nwcr-MuiPickersToolbar-root-MuiTimePickerToolbar-root {
	background-color: var(--cl_orange) !important;
	color: white !important;
}

.css-1hbyad5-MuiTypography-root {
	color: white !important;
}
.css-1nql4ji-MuiTypography-root {
	color: white !important;
}

.css-1nql4ji-MuiTypography-root.Mui-selected {
	color: white !important;
    text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3);
}

.css-frc9jk-MuiTypography-root {
	color: white !important;
}

.css-frc9jk-MuiTypography-root.Mui-selected {
	color: white;
    text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3);
    
}
