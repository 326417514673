

.eventImage{
    height: 480px;
    width: 100%;
    border-radius: 12px;
    background-color: var(--cl_bggrey);
}
.eventImage img{
    height: 480px;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 12px;

}
.eventContent{
    margin-top: 49px;
    display: grid;
    grid-template-columns: 1fr 312px;
    gap: 38px;
}
.eventcol1{
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-row-start: 1;
  grid-row-end: 2;
}

.test_grid_layout{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 5px;
}
.eventcol1 .eventUserDiv{
  display: flex;
  gap: 13px;
  align-items: center;
}
.eventcol1 .eventUserDiv img{
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;

}
.eventcol1 .eventUserDiv span{
  font-size: var(--font-size-13);
  font-weight: 400;
  color: rgba(125, 125, 141, 1);

}

.eventcol1 .eventDescription{
  margin-top: 20px;
  font-size: var(--font-size-13);
  line-height: 150%;
}
.eventcol1 .eventDescription p{
  white-space: pre-wrap;
}
.eventcol1 .eventprofilename{
  transition: all 0.3 s ease ease-in-out;
  color: black !important;
}
.eventcol1 .eventprofilename:hover{
  color: rgba(125, 125, 141, 1) !important;
}
.singleEventMap {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid var(--cl_light_grey);
}
.singleEventMap a,
.singleEventMap span {
  display: none;
  color: none;
  text-decoration: none;
}
.eventcol2{
  grid-row-start: 2;
  grid-column-start: 2;
}


  .eventcol3{
    grid-row-start: 1;
    grid-column-start: 2;
  }
  .eventcol3{
    grid-column-start: 2;
  }
  .eventcol3, .eventcol2{
      display: flex;
      flex-direction: column;
      gap: 16px;
  }
  .eventDate{
      /* height: 88px; */
      height: 111px;
      width: 312px;
      border: 1px solid var(--cl_light_grey);
      border-radius: 12px;
      padding: 25px;
      display: flex;
      /* align-items: center; */
      gap: 12px;
  }
  .eventDate svg{
    margin-top: 3px;
  }
  .eventDateDetailed{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .eventDateDetailed h5{
    font-size: 14px;
    font-weight: 600;
    color: rgba(4, 4, 5, 1);
  }
  .eventDateDetailed span{
    font-size: var(--font-size-13);
    font-weight: 400;
    color: rgba(125, 125, 141, 1);
  }
  .eventAssigned{
      width: 312px;
      /* height: auto;
      min-height: 100px;
      max-height: 235px; */
      border: 1px solid var(--cl_light_grey);
      border-radius: 12px;
      padding: 32px 20px 20px 32px;
      display: flex;
      flex-direction: column;
      gap: 23px;
  }
  .eventAssigned h3, .eventParticipants h3{
    font-weight: 600;
    font-size: var(--font-size-20);
  }
  .eventAssignedUsers{
    height: auto;
    max-height: 136px;
    width: 100%;
  }
  .eventAssignedUsers .featuredprofile{
    background-color: white;
    padding: 0 0 20px 0;
  }
  .eventAssignedUsers .featuredprofile img{
    background-color: var(--cl_lightgrey);
  }
  .eventAssignedUsers .featuredprofile .featuredNames h3{
    font-weight: 600;
    font-size: var(--font-size-13);
  }
  .eventAssignedUsers span,.eventParticipantsDp span{
    font-weight: 400;
    font-size: 11px;
    color: rgba(125, 125, 141, 1);
  }
  .notAssigned{
    height: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    /* align-items: center; */
    padding-bottom: 10px;
  }
  .eventParticipants{
      height: 136px;
      width: 312px;
      border: 1px solid var(--cl_light_grey);
      border-radius: 12px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      gap: 20px;
  }
  .eventParticipantsDp{
    display: flex;

  }
  .eventParticipants h3{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .eventParticipants h3 a{
    color: var(--cl_orange);
    font-size: var(--font-size-13);
    cursor: pointer;
  }
  .eventParticipants img{
    height: 32px;
    width: 32px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 50%;
    margin-right: 8px;
    transition: all 0.3s ease-in-out;
  }
  .eventParticipants img:hover{
    transform: scale(1.1);
  }
  
  .eventParticipantsCount{
    height: 32px;
    width: 32px;
    background-color: var(--cl_lightgrey);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .eventParticipantsCount span{
    color: rgba(125, 125, 141, 1);
    font-size: var(--font-size-10);
  }
  .eventDashboard, .eventCancel{
    height: 92px;
    width: 312px;
    border: 1px solid var(--cl_light_grey);
    border-radius: 12px;
    padding: 26px 32px;
    display: grid;
    grid-template-columns: 40px 1fr 20px;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    transition: 0.3s;
  }
  .eventDashboard:hover, .eventCancel:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: scale(1.001);
  }
  .eventOptions h5{
      font-size: 14px;
  }
  .eventOptions span{
      font-size: var(--font-size-13);
      font-weight: 400;
      color: rgba(125, 125, 141, 1);
}
.similarEvents{
  margin-top: 30px;
}
.similarEvents .commonHeading{
    display: flex;

}
.similarEvents .commonHeading svg{
    transform: translateY(2px);
}
@media (min-width: 1200px){
  .eventContent{
    grid-template-columns: 1fr 312px ;
  }
  .eventcol3{
    grid-column-start: 3;
  }
  .eventcol2{
    grid-row-start: 1;
    grid-column-start: 2;
  }
}
@media (min-width: 1400px){
  .eventContent{
    grid-template-columns: 1fr 312px;
  }
  .eventcol3{
    grid-column-start: 2;
  }
  .eventcol2{
    grid-row-start: 2;
    grid-column-start: 2;
  }
}
@media (min-width: 1600px){
  .eventContent{
    grid-template-columns: 1fr 312px;
  }
  .eventcol3{
    grid-column-start: 3;
  }
  .eventcol2{
    grid-row-start: 1;
    grid-column-start: 2;
  }
}

.reviewtriangle{
  position: absolute;
  /* background-color: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0),
      rgba(255, 142, 142, 0.5) 50%,
    ),
    rgb(170, 30, 30);

  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 1px solid rgba(199, 199, 202, 0.2) ; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  background-color: white;
  height: 15px;
  top: -7.5px;
  left: 150px;
  width: 15px;
  transform: rotate(-45deg);
  z-index: 0 !important;
}
.reviewcontainer{
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 50px ;
  /* box-shadow: rgba(199, 199, 202, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  /* margin: 10px 10px; */
  margin: 20px 0 10px 0;
  padding: 0px 10px 0 0;
  align-items: center;
  z-index: 1000;
}
.reviewbtncontainer{
  display: grid;
  grid-template-columns: 1fr 100px 100px;
  /* align-items: flex-end;
  justify-items: flex-end; */
  gap: 10px;
  padding-bottom: 10px;
}
/* .reviewinput{
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 0 5px 15px;
  resize: none;
  height: 70px;
  font-size: .9rem;
  font-family: "Inter", sans-serif;

} */
.reviewinput{
  /* height: 110px; */
  height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--cl_light_grey);
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    outline: none;
    border: none;
    font-family: "Inter", sans-serif;

    
}
.reviewbtn{
  margin-top: 10px;
}

.buyticket_event_1{
  display: none;
}
.buyticket_event_2{
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 1.5rem;
  width: 312px;
  border: 1px solid var(--cl_light_grey);
  border-radius: 12px;
}
@media (min-width: 1200px){

  .buyticket_event_1{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    gap: 1.5rem;
    width: 312px;
    border: 1px solid var(--cl_light_grey);
    border-radius: 12px;
    max-height: 160px;
  }
}
@media (min-width: 1400px){
  .buyticket_event_2{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    gap: 1.5rem;
    width: 312px;
    border: 1px solid var(--cl_light_grey);
    border-radius: 12px;
    max-height: 160px;
  }
  .buyticket_event_1{
    display: none;
  }
}
@media (min-width: 1600px){

  .buyticket_event_1{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    gap: 1.5rem;
    width: 312px;
    border: 1px solid var(--cl_light_grey);
    border-radius: 12px;
    max-height: 160px;
  }
}


.eventfooter_icons{
  padding: 40px 0 20px 0;
  display: flex;
  border-top: 1px solid #E2E2F0;
  margin-top: 20px;
  gap: 1.5rem;
}
.eventfooter_item{
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  justify-items: center;
  gap: 1rem;

}

.eventfooter_itemicon{
  height: 40px;
  width: 40px;
}
/*ticket*/
.event_ticket_icon svg{
  height: 30px;
  width: 30px;
}
.event_ticket_list>div{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.eventSubscriptionBtn{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.eventSubscriptionBtn_active{
  background-color: var(--cl_light_orange);
}
.eventSubCount{
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  color: white;
  background-color: var(--cl_orange);
  top: -2.5px;
  right: -2.5px;
  font-size: .5rem;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}