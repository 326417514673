.miniEventDetail{
    display: grid;
    grid-template-columns: 1fr 312px;
}
.miniEventDetail .commonHeading{
    gap: 20px;
}
.miniEventDetail .commonHeading h1{
    font-size: 2rem;
    font-weight: 600;
}
.miniEventUser{
    display: flex;
    align-items: center;
    gap: 13px;
    cursor: pointer;
}
.miniEventUser img{
    height: 32px;
    width: 32px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 50%;
}
.miniEventUser span:hover{
    color: black;
}