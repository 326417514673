

.policyLayout{
    padding: 50px 30px;
    /* min-height: 80vh; */
    display: flex;
    align-items: center;
    min-height: 100vh;
  }
  .margincalc{
    margin:0 calc(38% - 20px);
  }
  @media only screen and (min-width: 768px) {
    .policyLayout{
      padding: 50px 12%;
    }
  }
  .progresslayout{
    color: var(--cl_orange);
    height: 100%;
    width: 100%;
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
  }