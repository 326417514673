.storycontainer {
	background-color: #16161a;
}
.storywrapper {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* ############################### STORY WRAPPER ################################# */

.story__wrapper__container {
	/* border: 1px solid red; */
	height: 180px;
	padding: 0px 5px;
	display: flex;
	gap: 10px;
	align-items: center;
	width: 100%;
	overflow-x: scroll;
	position: sticky;
	top: 0;
}

.story__wrapper__container{

}

/* ############################### STORY TILE ################################# */

.story__tile__container {
	min-width: 130px !important;
	max-width:130px !important;
	height: 170px;
	/* border: 1px solid blue; */
	display: flex;
	/* align-items: space-between; */
	flex-direction: column;
	justify-content: space-between;
	border-radius: 10px;
}

.story__tile__image {
	width: 100%;
	/* height: 100%; */
	/* background-color: rgba(125, 125, 141, 0.08); */
	padding: 10px;
	display: flex;
	/* gap: 13px; */
	align-items: center;
	border-radius: 12px;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}
.story__tile__image img:hover {
	transform: scale(1.1);
	/* box-shadow: rgba(255, 89, 23, 0.08) 0px 2px 8px 0px; */
}
.story__tile__image img {
	height: 36px;
	width: 36px;
	object-fit: cover;
	-o-object-fit: cover;
	object-position: center;
	-o-object-position: center;
	border-radius: 50%;
	transition: transform 0.3s ease-in-out;
	box-shadow: rgba(255, 89, 23, 0.08) 0px 2px 8px 0px;

}
.story__tile__image_loader
	{
	height: 36px;
	width: 36px;
	object-fit: cover;
	-o-object-fit: cover;
	object-position: center;
	-o-object-position: center;
	border-radius: 50%;
	transition: transform 0.3s ease-in-out;
	box-shadow: rgba(255, 89, 23, 0.08) 0px 2px 8px 0px;
}

.story__tile__username {
	color: white;
	font-size: 0.95rem;
	font-weight: 400;
	letter-spacing: 1px;
	font-family: 'Inter', sans-serif;
	padding: 11px;
}
