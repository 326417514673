.discover{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.discoverPage{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 9px;
}