.notificationDropdown{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  top: 50px;
  right: -22px;
  min-width: 28rem !important;
  height: auto;
  /* max-height: 70vh; */
  transition: display 0.4s ease,opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 10;
}
.notificationDropdownactive {
    border: 0.5px solid rgba(224, 222, 222, 0.863);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 1.5px rgb(202, 201, 201);
    transform: translateY(0px);
    opacity: 1;
}
.notificationDropdowninactive {
    border: 1px solid rgba(233, 230, 230, 0.863);
    border-top: transparent;
    border-radius: 10px;
    transform: translateY(-15px);
    opacity: 0;
    pointer-events: none;
}
.notificationDropdowninactive .notificationItem{
 border: 0px solid rgba(0, 0, 0, 0) !important;
}
.notifications {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-content: center;
    padding: 15px 15px;
    background-color: white;
    z-index: 100;
    /* border: .5px solid rgba(238, 236, 234, 0.863); */
    /* border-left: transparent; */
    border-radius: 10px 10px 0px 0px;
  
    /* border-top: transparent; */
    /* border-left: transparent; */
    /* border-right: transparent;  */
}
.notifications h4 {
    font-size: 1rem;
    font-weight: 600;
}
.notifications span {
    position: absolute;
    right: 38px;
    top: -6px;
    display: block;
    height: 10px;
    width: 10px;
    background-color: white;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
}
.notificationsLink {
    font-size: .85rem;
    font-weight: 400;
    color: var(--cl_orange);
    text-decoration: none;
    display: flex;
    align-items: center;
}
.notificationsLink:hover {
  color: #d43c00;
}
.UserNotificationListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  min-height: 80px;
  max-height: 120px;
  gap: 5px;
  border: 0.2px solid rgb(226, 228, 226);
  border-bottom: transparent;
  border-left: transparent;
  border-right: transparent;
  /* border: 1px solid blue; */

  /* align-items: center; */
  padding: 10px 8px;
  /* justify-content: space-evenly; */
  background-color: white;
  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 2px rgb(211, 211, 211); */
  z-index: 100;
}
.UserNotificationListItem:hover {
  background-color: var(--cl_light_grey);
  background-size: 100%;
}

.UserNotificationPageListItemsection1 {
  display: flex;
  /* position: relative; */
  /* align-items: center; */
  flex-direction: row;
  /* border: 1px solid blue; */
  gap: 5px;
  /* background-color: chartreuse; */
  /* width: 18vw; */
  /* width: 100%; */
  /* min-width: 18vw; */
  /* min-height: 6vh;
  max-height: 6vh; */
}
/* .UserNotificationPageListItemsection1:hover {
  background-color: blue;
  background-size: 100%;
} */


.UserNotificationProfileDp {
  display: flex;
  /* border: 1px solid rgb(0, 41, 128); */

  height: 35px;
  min-height: 35px;
  width: 35px;
  min-width: 35px;
  border-radius: 50% !important;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 0.1%;
}
.UserNotificationDp {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.UserNotification {
  text-align: left;
  margin-left: 5px;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.UserNotificationName {
  font-size: 0.85rem;
  font-weight: 600;
  /* loader */

}
.UserNotificationNameloader{
  max-width: 150px;
  min-height: 0.85rem;
}
.UserNotificationtime {
  font-size: 0.6rem;
  font-weight: 500;
  color: #76767a;
}
.UserNotificationtimeloader{
  max-width: 50px;
  min-height: 0.6rem;
}
.UserNotificationMessage {
  font-size: 0.7rem;
}
.UserNotificationMessageloader {
  min-width: 300px;
  min-height: 0.7rem;
}
.notificationbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 50px;
  background-color: white;
}
  /* .notificationDropdownactive .topBarDivBtn {
    height: 35px;
    width: 100px;
    align-self: center;
    margin: 5px 0;
    font-weight: 100;
  } */



.Message {
  display: flex;
  justify-content: center;
  border: 0.5px solid rgb(199, 197, 197);
  font-size: 12px;
  /* min-width: 5vw; */
  /* max-width: 7vw; */
  font-weight: 600;

  padding: 5px 12px;
  background-color: white;
  border-radius: 5px;
}
.UserMessage {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  border: 0.5px solid rgb(199, 197, 197);
  /* min-width: 4vw; */
  /* max-width: 5vw; */
  padding: 5px 12px;
  background-color: white;
  border-radius: 5px;
}

.notificationPageLayout {
  width: 100%;
  height: auto;
  min-height: 100vh;
  /* border: 1px solid red; */
  padding: 150px 40px 0 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.notificationPageLayout .exploreDivTitle{
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.notificationsicons{
  display: flex;
  gap: 5px;
}


.notificationsettingsicon{
  fill: none;
  transition: all 0.3s ease-in-out;
}
.notificationsettingsicon circle{
  fill: white;
  stroke: #E2E2F0;
}
.notificationsettingsicon path{
  stroke: #040405;
}
.notificationsettingsicon:hover > circle{
  fill: var(--cl_orange);
  stroke: none;
}
.notificationsettingsicon:hover > path{
  stroke: white;
}


.notificationsClear{
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}
.notificationsClear:hover{
  background-color: rgba(255, 0, 0, 0.2);
}


  