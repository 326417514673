.miniProfile{
    height: 390px;
    width: 312px;
    border: 1px solid rgba(125, 125, 141, 0.16);
    border-radius: 12px;
    padding: 27px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.miniProfile .pop{
    padding-bottom: 0 !important;
}
.miniProfileTop{
    display: flex;
    justify-content: space-between;
}
.miniprofileicons{
    display: flex;
    gap: 16px;
}
.miniprofileicon{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.miniprofileicon svg{
    stroke: black;
}
.miniprofileicon:hover svg{
    stroke: #FF5917;
}




.profileselectdropdown {
    background: #ffffff;
    border-radius: 10px;
    top: 55px;
    right: -4px;
    width: 356px;
    min-width: 356px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    padding: 8px;
}
@media (min-width: 1400px){
    .profile .profileselectdropdown{
        top: 55px;
        right: initial;
    }
}
@media (min-width: 1400px){
    .nw_profile .profileselectdropdown{
        right: -4px;
    }
}
.profileselectdropdown span{ 
    position: absolute;
    top: -6px;
    right: 20px;
    display: block;
    height: 10px;
    width: 10px;
    background-color: white;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
}
@media (min-width: 1400px){
    .profile .profileselectdropdown span{
        left: 15px;
    }
}
@media (min-width: 1400px){
    .nw_profile .profileselectdropdown span{
        right: 20px;
    }
}
.profileselectdropdown.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
}
.profileselectdropdown.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.profileselectdropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.profileselectdropdown li {
    /* font-size: var(--font-size-13);
    font-weight: 500; */
    transition: background-color 0.2s ease-in-out;
    border-radius: 8px;
    /* padding: 15px 20px; */
    display: block;
    padding: 12px 16px;
    /* display: grid;
    grid-template-columns: 48px 1fr;
    gap: 16px; */
}
.profileselectdropdown .profileslistitem {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 16px;
}
.profileselectdropdown li img{
    width: 48px;
    height: 48px;
    border-radius: 12px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.profileselectdropdown li h5{
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
}
/* .profileselectdropdown li a {
    text-decoration: none;
    color: #040405;
    padding: 15px 20px;
    display: block;
    transition: color 0.2s ease-in-out;
} */
.profileselectdropdown .profileslistitem:hover {
    color: var(--cl_orange);
    background-color:var(--cl_light_orange);
    border-radius: 8px;

}
.profileselectdropdown li .profilelistitemcontent{
    display: grid;
    grid-template-columns: 1fr 24px;
    gap: 5px;
    align-items: center;
}
.profilelistitemdetails{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    
}
.profileselectdropdown li div small{
    font-size: 12px;
    color: #7D7D8D;
}
.profileselectdropdown li small{
    font-size: 13px;
    color: #7D7D8D;
    
}

/* .profileselectdropdown li:hover > a{
    color: var(--cl_orange);
} */

.roundedcheckbox{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #E2E2F0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.roundedcheckboxactive{
    background-color: var(--cl_orange);
}
.roundedcheckboxdot{
    background-color: white;
    height: 7px;
    width: 7px;
    border-radius: 50%;
}

















.miniProfileTop span{
    font-size: var(--font-size-12);
    font-weight: 400;
}
.publicStatus{
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
}
.errorMessagePop{
    position: absolute;
    background-color: black;
    min-height: 80px;
    height: auto;
    width: 200px;
    top: 30px;
    right: 0;
    border-radius: 12px;
    z-index: 1000;
    color: white;
    transition: all 0.3s ease-in-out;
}
.errorMessagePopActive{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.errorMessagePopInActive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
}
.errorMessageWrapper{
    position: relative;
    padding: 15px;
}
.errorMessageTriangle{
    position: absolute;
    background-color: black;
    min-height: 13px;
    min-width: 13px;
    height: 15px;
    top: -6.5px;
    right: 12px;
    /* left: 150px; */
    width: 15px;
    transform: rotate(-45deg);
}
.profileToggle{
    height: 20px;
    width: 32px;
    padding: 3px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: justify-content 0.3s ease-in-out,background-color 0.3s ease-in-out;
}
.profileToggleDot{
    background-color: white;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
}
.profileToggleActive{
    background-color: var(--cl_orange);
    /* justify-content: flex-end; */
}

.profileToggleActive .profileToggleDot{
    transform: translateX(12px);
}
.profileToggleInactive{
    background-color: var(--cl_bggrey);
}
.profileToggleInactive .profileToggleDot{
    transform: translateX(0px);
}

.miniProfileMid{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.miniProfileSub{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}
.miniProfileSub h2{
    font-size: 20px;
    font-weight: 600;

}
.miniProfileSub span{
    color: rgba(125, 125, 141, 1);
    font-size: var(--font-size-12);
    font-weight: 400;
}
.miniProfile .btnContainer{
    display: flex;
    justify-content: center;
}
.miniProfile .btnContainer .mainBtnInv{
    padding-left: 15px;
    padding-right: 15px;
}