

.miniFeatured{
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 10px;
}
.miniFeaturedHead{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-items: center;
}
.miniFeaturedHead h2{
    font-size: var(--font-size-20);
    font-weight: 600;
}
.miniFeaturedHead a{
    font-size: var(--font-size-13);
    color: var(--cl_orange);
    text-decoration: none;
    font-weight: 500;
}
.miniFeaturedList{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.miniFeatured .featuredprofile .mainBtn{
    font-family: "Inter", sans-serif;
    height: 32px; 
}
.miniFeatured .featuredprofile .mainBtnInv{
    font-family: "Inter", sans-serif;
    height: 32px;
}