.scrolling-wrapper {
	overflow: hidden;
	overflow-y: hidden;
	white-space: nowrap;
}


.exploreBoxDiv1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	border: 0.5px solid rgb(238, 238, 238);
	border-radius: 8px;
	align-items: center;
	width: auto;
	min-width: 200px;
	min-height: 65px;
	padding: 0px 15px;
	cursor: pointer;
	position: relative;

}

.exploreBoxDiv2 {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	/* border: 0.5px solid rgb(238, 238, 238); */
	background-color: var(--cl_lightgrey2);
	border-radius: 8px;
	align-items: center;
	width: auto;
	min-width: 200px;
	min-height: 65px;
	padding: 0px 15px;
	cursor: pointer;
	position: relative;

}

.exploreBoxDiv1:hover {
	background-color: var(--cl_light_orange);
	border-color: var(--cl_light_orange);
}

/* .wl-featured-gridLayout{
    border : 1px solid blue;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto ;
} */





.eventSearchImgWl {
	object-fit: contain;
	height: 100%;
	max-width: 100%;
	position: relative;
	top: 10.5%;
	z-index: 2;
	margin : 3% 2% 0 2%;
}

.eventSearchImgWl_wrapper {
	height: 480px;
}

/* @media(max-width : 1850px) {
	.eventSearchImgWl {
		object-fit: contain;
		height: 100%;
		max-width: 100%;
		position: relative;
		top: 18.5%;
		z-index: 2;
		margin : 3% 2% 0 2%;
	}
} */



/* @media(min-width : 1600px) {
	.eventSearchImgWl {
		object-fit: contain;
		height: 100%;
		max-width: 90%;
		position: relative;
		top: 20.5%;

		z-index: 2;
	}
		.eventSearchImgWl_wrapper {
		height: 380px;
	}



} */


@media(max-width : 1550px) {
	.eventSearchImgWl {
		object-fit: contain;
		height: 100%;
		max-width: 90%;
		position: relative;
		top: 10.5%;
		z-index: 2;
	}
		.eventSearchImgWl_wrapper {
		height: 380px;
	}



}

@media(max-width : 1450px) {
	.eventSearchImgWl {
		object-fit: contain;
		height: 100%;
		max-width: 90%;
		position: relative;
		top: 20%;
		margin-top: 0;
		z-index: 2;
	}
		.eventSearchImgWl_wrapper {
		height: 380px;
	}



}

@media(max-width : 1350px) {
	.eventSearchImgWl {
		object-fit: contain;
		height: 100%;
		max-width: 90%;
		position: relative;
		top: 25.5%;
		margin-top: 0;
		z-index: 2;
	}
		.eventSearchImgWl_wrapper {
		height: 380px;
	}



}

@media(max-width : 1250px) {
	.eventSearchImgWl {
		object-fit: contain;
		height: 100%;
		max-width: 90%;
		position: relative;
		top: 26%;
		margin-top: 0;
		z-index: 2;
	}

	.eventSearchImgWl_wrapper {
		height: 380px;
	}



}

@media(max-width : 1100px) {
	.eventSearchImgWl {
		object-fit: contain;
		height: 90%;
		width: 100%;

		top: 26%;
		z-index: 2;
	}


} 