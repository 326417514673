.browse_wrapper{
    display: grid;
    grid-template-rows: auto 1fr;

}

.browse{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
}
.browseSidebar{
    position: relative;
    display: none;
}
.browseSidebarWrapper{
    /* height: 100vh; */
    position: sticky;
    position: -webkit-sticky;
    top: 152px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: calc(100vh - 152px) !important;
}
.browseSidebarWrapper .miniFeatured{
    height: 100%;
    padding-top: 0 !important;
    display: grid;
    grid-template-rows: 30px 1fr;
    gap: 20px;
}
.browseMain{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.miniFeaturedListScroll>div{
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-right: 3px;
    /* padding-left: 40px; */
}
.miniFeaturedListScroll .featuredprofileloader{
    min-height: 68px;
}
@media (min-width: 1500px) {
    .browse{
        grid-template-columns: 1fr 312px;
        gap: 40px;
    }
    .browseSidebar{
        position: relative;
        display: block;
    }
  }