/* .eventShareModal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

}
.eventShare{
    height: 500px;
    width: 700px;
    background-color: white;
    border-radius: 12px;
} */
.ShareWrapper{
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}
.ShareWrapper img{
    width: 701px;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.shareContent{
    height: 60%;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1),rgba(255, 255, 255, 0.9), white);
}
.shareContent .noData{
 gap: 15px;
}
.shareContent .noData span{
    color: black;
}
.NewEventCreatedSocialDiv{
    display: flex;
    gap: 10px;
}
.NewEventCreatedSocialDiv svg{
    font-size: 1.5rem;
}
.shareInputDiv{
    display: flex;
}
.shareinput{
    height: 40px;
    border: none;
    outline: none;
    border-radius: 8px;
    min-width: 400px;
    padding-left: 15px;
}
.copyDiv{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
}
.copyDivactive{
    background-color: var(--cl_orange);
}
.copyDivactive svg{
    color: white;
}