.PaymentTabItem__wrapper {
  /* height: 380px;    */
  /* padding-bottom:10px ; */
  display: flex;
  /* grid-template-columns:1fr; */
  /* grid-template-rows: 210px 1fr; */
  border-radius: 12px;
  border: 1px solid rgba(125, 125, 141, 0.16);
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.PaymentTabItem__wrapper:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.PaymentTabItem__Image {
  width: 300px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.PaymentTabItem__Image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.amount__div {
  margin: 0 5px 0 0px;
  color: black;
  font-size: 1rem;
  font-weight: 500;
  display: flex;


}

.event__time {
  margin: 0 5px 0 0px;
  color: rgb(136, 136, 136);
  font-size: 1rem;
  /* height: .7rem;
    height: .7rem; */
  /* min-width: 100px; */
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.paymentstatus__wrapper {
  display: flex;
  align-self: center;
  padding: 10px;
  /* justify-self: flex-end; */
}


.payment__status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  /* margin: 0px 15px; */
  padding: 0px 10px;
  border-radius: 5px;
  font-size: 0.85rem;
  height: 30px;
  font-weight: 500;
  text-transform: uppercase;
  /* letter-spacing: .2px; */
}

.green {
  border: 1.5px solid darkgreen;
  background: darkgreen;
  color: white;

}

.red {
  border: 1.5px solid darkred;
  background: darkred;
  color: white;

}

.blue {
  border: 1.5px solid darkblue;
  background: darkblue;
  color: white;

}




.PaymentTabItem__eventdetail {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 5px;
  flex-grow: 1;
}

.mainBtn {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-items: center;
}